import styled from "styled-components";
import { useState } from "react";

export default function ModalEditCategory(props){
    const [idCategory] = useState(props.idCategory);
    const [nombre, setNombre] = useState(props.name);
    const [description, setDescription] = useState(props.description);
    const [showModal, setShowModal] = useState(false);
    const [showBackdrop, setShowBackdrop] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        props.getContentEditCategory(idCategory, nombre, description);
        setShowModal(false);
        setShowBackdrop(false);
    }

    const openModal =(e) => {
        setShowModal(true);
        setShowBackdrop(true);
    }

    const closeModal =(e) => {
        setShowModal(false);
        setShowBackdrop(false);
    }

    return(
        <>
            {/* <!-- Button trigger modal --> */}
            <button type="button" className="btn btn-primary" onClick={openModal}>
                {props.nameButon}
            </button>

            {/* <!-- Modal --> */}
            {showModal && 
                <div className="modal" tabIndex="-1" role="dialog" style={{display: 'block'}}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title fs-5">{props.title}</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <StyleModalEditCategory>
                                    <div className="containerFrom">
                                        <div className="containerRowFrom">
                                            <label htmlFor='name'>Nombre:</label>
                                            <input type="text" id='name' name='name' value={nombre} onChange={e => setNombre(e.target.value)}/>
                                        </div>
                                        <div className="containerRowFrom">
                                            <label htmlFor='description'>Descripción:</label>
                                            <input type="text" id='description' name='description' value={description} onChange={e => setDescription(e.target.value)}/>
                                        </div>
                                    </div>
                                </StyleModalEditCategory>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Cancelar</button>
                                        <button onClick={handleSubmit} type="button" className="btn btn-primary">Guardar</button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {/* <!-- Modal backdrop --> */}
            {showBackdrop && <div className="modal-backdrop fade show"></div>}
        </>
    )
}

const StyleModalEditCategory = styled.div`
    .containerFrom{
        display: flex;
        flex-direction: column;
    }
    .containerRowFrom{
        display: flex;
        justify-content: flex-end;
    }
`