import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PriceService from "../../services/PriceService";
import CustomerService from '../../services/CustomerService';
import toThousand from '../../services/ToThousandService';
import Barton1000Price from "../../component/prices/Barton1000Price.jsx";
 
export default function Calculating (){

    const [value1, setValue1] = useState('');
    const [value2, setValue2] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [result15, setResult15] = useState(null);
    const [result25, setResult25] = useState(null);
    const [result30, setResult30] = useState(null);
    const [result15Inv, setResult15Inv] = useState(null);
    const [result25Inv, setResult25Inv] = useState(null);
    const [result30Inv, setResult30Inv] = useState(null);
    const [dolar, setDolar] = useState([]);

    useEffect(() => {
        const getDolar = async () => {
            const dolarData = await CustomerService();
            setDolar(dolarData);
        };
        getDolar();
    }, []);
    
    const price = PriceService.prices //obtengo la lista de precio
    
    // Función para manejar el cambio en el primer input
    const handleValue1Change = (event) => {
        const newValue = Math.abs(parseFloat(event.target.value)); // Obtener el valor absoluto
        setValue1(newValue);
    };

    // Función para manejar el cambio en el segundo input
    const handleValue2Change = (event) => {
        const newValue = Math.abs(parseFloat(event.target.value)); // Obtener el valor absoluto
        setValue2(newValue);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const areInputsValid = () => {
        return value1 !== '' && value2 !== '' && selectedOption !== null;
    };

    // Función para calcular canridad de barrras
    const calculateProduct = () => {
        const num1 = parseFloat(value1);
        const num2 = parseFloat(value2);

        const calculo = (num, coef, aux) => { return  Math.floor(num / coef) === (num /coef) ? (num / coef) * aux : (Math.floor(num / coef) +1 ) * aux }

        const div15 = calculo (num1, 0.15, num2);
        const div25 = calculo (num1, 0.25, num2);
        const div30 = calculo (num1, 0.3, num2);

        const div15Inv = calculo (num2, 0.15, num1);
        const div25Inv = calculo (num2, 0.25, num1);
        const div30Inv = calculo (num2, 0.3, num1);

        setResult15(div15);
        setResult25(div25);
        setResult30(div30);

        setResult15Inv(div15Inv);
        setResult25Inv(div25Inv);
        setResult30Inv(div30Inv);
    };

    return (
        <div style={{backgroundColor:'var(--colorFilaTabla'}}>
     
        <div className="container-fluid">
            <div className="row" style={{paddingBottom: '5rem'}}>
                <h2 style={{marginTop: '5rem', textAlign: 'center'}}>Calcula tu próximo ahorro con Barton</h2>
                <h2 style={{margin: '1rem 0rem', textAlign: 'center'}}><i className="fa-solid fa-calculator"></i></h2>

                <div className="col-12">
                    <StyleCalculating>
                        <div className="containerInputs">
                            <label style={{fontSize: '1.5rem', fontWeight: '600', color: 'var(--colorTerciario)'}} htmlFor="diametro" className='titleTextResult'>Seleccione su diámetro de Barton</label>
                            <select name="diametro" id="diametro" className="form-select" onChange={handleSelectChange} style={{margin: '1rem 0rem'}}>
                                <option value="" disabled selected>Diámetro (mm)</option>
                                <option value="0">Diámetro 5</option>
                                <option value="1">Diámetro 6</option>
                                <option value="3">Diámetro 8</option>
                                {/* <option value="4">Diámetro 10</option>
                                <option value="5">Diámetro 12</option> */}
                            </select>
                            <label htmlFor="" className='titleTextResult' style={{fontSize: '1.5rem', fontWeight: '600', color: 'var(--colorTerciario)', marginTop: '2rem'}}>¿Que medida tiene el Piso?</label>
                            <input  className="form-control" style={{margin: '1rem 0rem'}} type="number" 
                                    value={value1} onChange={handleValue1Change} placeholder="Ingrese lado A (metros)" />
                            <input  className="form-control" style={{margin: '1rem 0rem'}} type="number"
                                    value={value2} onChange={handleValue2Change} placeholder="Ingrese lado B (metros)" />

                            <div style={{marginTop: '1.5rem', textAlign: 'center', marginBottom: '1rem' }}>
                                <button type="button" className="btn btn-primary" style={{width: '50%'}} 
                                        onClick={calculateProduct} disabled={!areInputsValid()}>Calcular</button>
                            </div>
                        </div>
                    </StyleCalculating>

                    {/* Mostrar el resultado */}
                    {result25 !== null && (
                        <StyleResult>
                            <div className="container-fluid">
                                <br />

                                <div className="row">
                                    <div className="col-12">
                                            <div className="containerTextResult">
                                                <div className="containerImgResult">
                                                    <h4>Opción 1</h4>
                                                    <img src="../../../assets/img/benefitsFlat/30x30.jpeg" alt="malla 30x30" />
                                                </div>
                                                <div className="contenTextResult">
                                                    <h3 className='titleTextResult'>Equivalente en tracción</h3>
                                                    <p style={{marginTop: '2rem'}}>Separación de 30x30 cm:  <strong>{result30 + result30Inv} metros lineales </strong></p>

                                                    <p>Unidades de Barton: <strong>{Math.ceil((result30 + result30Inv)/100)} rollos</strong></p>
                                                    <p>Peso Total: <strong>{(Math.ceil((result30 + result30Inv)/100)*price[selectedOption].pesoKG)} kg</strong></p>

                                                    <br />
                                                    <p>Precio lineal Total Dolar: <strong>{toThousand(((result30 + result30Inv) * price[selectedOption].precioLineal)) } U$S </strong> + iva</p>
                                                    <p>Precio lineal Total Pesos: <strong>{toThousand(((result30 + result30Inv) * price[selectedOption].precioLineal)*dolar) } $</strong> + iva</p>
                                                </div>
                                                <div className="containerImgResult">
                                                    <h4>Acero 420</h4>
                                                    <img src="../../../assets/img/benefitsFlat/15x15adn.jpeg" alt="malla adn 30x30" />
                                                </div>
                                            </div>
                                    </div>
                                </div>


                                <br />
                                <div className="row">
                                    <div className="col-12">
                                        <div className="containerTextResult">
                                            <div className="containerImgResult">
                                                <h4>Opción 2</h4>
                                                <img src="../../../assets/img/benefitsFlat/25x25.jpeg" alt="malla 25x25" />
                                            </div>
                                            <div className="contenTextResult">
                                                <h3 className='titleTextResult'>Reforzada</h3>
                                                <p style={{marginTop: '2rem'}}>Separación de 25x25 cm:  <strong>{result25 + result25Inv} metros lineales </strong></p>

                                                <p>Unidades de Barton: <strong>{Math.ceil((result25 + result25Inv)/100)} rollos</strong></p>
                                                <p>Peso Total: <strong>{(Math.ceil((result25 + result25Inv)/100)*price[selectedOption].pesoKG)} kg</strong></p>
                                                
                                                <br />
                                                <p>Precio lineal Total Dolar: <strong>{toThousand(((result25 + result25Inv) * price[selectedOption].precioLineal)) } U$S </strong></p>
                                                <p>Precio lineal Total Pesos: <strong>{toThousand(((result25 + result25Inv) * price[selectedOption].precioLineal)*dolar) } $</strong></p>
                                            </div>
                                            <div className="containerImgResult">
                                                <h4>Acero 420</h4>
                                                <img src="../../../assets/img/benefitsFlat/15x15adn.jpeg" alt="malla adn 15x15" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <br />
                                <div className="row">
                                    <div className="col-12">
                                        <div className="containerTextResult">
                                            <div className="containerImgResult">
                                                <h4>Opción 3</h4>
                                                <img src="../../../assets/img/benefitsFlat/15x15.jpeg" alt="malla 15x15" />
                                            </div>
                                            <div className="contenTextResult">
                                                <h3 className='titleTextResult'>Sobredimensionada</h3>
                                                <p style={{marginTop: '2rem'}}>Separación de 15x15 cm:  <strong>{result15 + result15Inv} metros lineales </strong></p>

                                                <p>Unidades de Barton: <strong>{Math.ceil((result15 + result15Inv)/100)} rollos</strong></p>
                                                <p>Peso Total: <strong>{(Math.ceil((result15 + result15Inv)/100)*price[selectedOption].pesoKG)} kg</strong></p>
                                                
                                                <br />
                                                <p>Precio lineal Total Dolar: <strong>{toThousand(((result15 + result15Inv) * price[selectedOption].precioLineal)) } U$S </strong></p>
                                                <p>Precio lineal Total Pesos: <strong>{toThousand(((result15 + result15Inv) * price[selectedOption].precioLineal)*dolar) } $</strong></p>
                                            </div>
                                            <div className="containerImgResult">
                                                <h4>Acero 420</h4>
                                                <img src="../../../assets/img/benefitsFlat/15x15adn.jpeg" alt="malla adn 15x15" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </StyleResult>
                    )}
                </div>
            </div>
        </div>
        </div>
    );
}

const StyleCalculating = styled.div `
    .containerInputs {
        width: 100%;
        @media (min-width: 768px) {
            width: 30%;
            margin: 0 auto;
        }
    }
`
const StyleResult = styled.div`
    .containerTextResult{
        border: 1px solid var(--colorFilaTabla);
        border-radius: 0.5rem;
        padding: 2rem 1rem;
        width: 100%;
        background-color: #FFF;
        @media (min-width: 768px) {
            display: grid;
            grid-template-columns: repeat(3, 33%);
            
        }
    }
    .titleTextResult{
        color: var(--colorTerciario);
        font-size: 1.6rem;
        margin: 2rem 0rem;
        text-align: center;
        @media (min-width: 768px) {
            margin: 0rem;
            font-size: 2.3rem;
        }
    }
    .contenTextResult{
        text-align: start !important;
    }
    .containerImgResult{
        width: 50%;
        margin: 0 auto;
        text-align: center;
        @media (min-width: 992px){
            width: 40%;
        }
    }
    img{
        width: 100%;
        margin-top: 1.5rem;
        @media (min-width: 992px){
            width: 100%;
        }
    }
    p{
        text-align: start;
    }
    
`