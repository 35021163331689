import styled from "styled-components";
import { useState } from "react";
import LoginService from '../../services/LoginService'
import BartonService from '../../services/BartonService'

export default function LoginView(props){

    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');

    async function handleSubmit (e) {
        const data = {
            user: user,
            password: password
        }
        const response = await LoginService.login(data)
        if (response !== '') {
            localStorage.setItem("token", response.data.token);
        }
    }

    async function handle2 (e) {
        const response = await BartonService.nuevoPrueba()
        if (response !== '') {
            console.log(response)
        }
    }

    return(
        <StyleLoginView>
                <form className="containerLogin">
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">Email</label>
                        <input 
                            className="form-control"
                            type="email"
                            onChange={e => setUser(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleInputPassword1" className="form-label">Contraseña</label>
                        <input 
                            className="form-control" 
                            type="password"
                            onChange={e => setPassword(e.target.value)}
                            />
                    </div>
                    <button onClick={e => handleSubmit()} type="button" className="btn btn-primary">Ingresar</button>
                </form>

                <button type="button" onClick={e => handle2() }>Ver categorias en consola</button>
        </StyleLoginView>
    )
}

const StyleLoginView = styled.form`
    .containerLogin{
        margin-top: 3rem;
        padding: var(--paddinLateralMobile)
    }
    @media(min-width: 768px){
        padding: var(--paddinLateral)
    }
`