import styled from "styled-components";
import { useWindowWidth } from "../../hooks/useWindowWidth";

export default function Benefit(props) {

    const [windowWidth] = useWindowWidth();
    let img = props.benefitInfo.img;
    let logo = props.benefitInfo.logo;
    
    return (
        <StyledBenefit>
            <div className="containerBenefitt">
                <div className="containerBenefitImg">
                    <img className="benefitImg" src={img} alt={img} />
                </div>
                <div className="contanerTitleBenefit">
                    <h5>{props.benefitInfo.title}</h5>
                </div>
                {windowWidth > 768 && (
                    <div className="ventajasImagen">
                        <img src={logo} alt={logo} />
                    </div>
                )}
            </div>
        </StyledBenefit>
    );
}

const StyledBenefit = styled.div`
    
    flex: 0 0 calc(20% - 5px); /* Ajusta el ancho para 5 elementos */
    margin: 5px;
    bonder-radius: 5px;
    @media (min-width: 768px) {
        margin: 0px;
    }
    .containerBenefitt {
        width: 100%;
        img {
            width: 100%;
        }
    }

    .containerBenefitImg {
        overflow: hidden; 
        height: 200px;
        border-radius: 5px 5px 0px 0px;
        @media (min-width: 576px) {
            height: 500px;
        }
    }
    .benefitImg {
        min-height: 100%;
        object-fit: cover;
    }
    
    .contanerTitleBenefit{
        background-color:var(--colorTerciario);
        display: flex;
        min-height: 3rem;
        align-items: center;
        text-align: center;
        border-radius: 0px 0px 5px 5px;
        @media (min-width: 1400px) {
            min-height: 5rem;
        }
    }
            
    .contanerTitleBenefit h5 {
        color: #fff;
        margin: 0 auto;
        font-size: 1.1rem;
    }

    .ventajasImagen {
        margin-top: 20px;
        text-align: center;
        img {
            width: 40%;
            margin: 0 auto;
            margin-bottom: 10px;
        }
    }
`