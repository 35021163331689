import { HashLink } from "react-router-hash-link"
import styled from "styled-components"

export default function ProductCard(props){
    
    return(
        <StyleProductCard>
            <div className="card" style={{marginBottom: '1rem'}}>
                <HashLink to={props.cardInfo.link}>
                    <div className="card-img">
                        <img src={props.cardInfo.img} className="card-img-top" alt={props.cardInfo.title}/>
                    </div>
                    <div className="card-body" style={{textAlign: 'center'}}>
                        <h3 className="title-card-body">{props.cardInfo.title}</h3>
                    </div>
                </HashLink>
            </div>
        </StyleProductCard>
    )
}

const StyleProductCard = styled.div `
    .title-card-body {
        font-size: 1.2rem;
        color: var(--colorTerciario);
        padding: 0.6rem;
        margin-bottom: 0rem;
        @media (min-width: 768px) {
            font-size: 1.5rem;
        }
    }
    .card-img {
        overflow: hidden; 
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
    }
    .card-img-top {
        min-height: 100%;
        object-fit: cover;
        }
        img {
            transition: 0.2s;
        }
        img:hover {
            transition: 0.1s;
            transform: scale(1.05);
    }
`