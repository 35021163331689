import Accordion from './Accordion';
import styled from 'styled-components';

export default function AccordionsGroup(props) {
    return (
      <StyledAccordionsGroup style={{width: '100%'}}>
        <section className='col-12'>
            <div className="accordion contenAccordion" id="accordionExample">
              {props.sampleList.map((data) => (
                  <Accordion key={data.id} cardInfo={data} />
              ))}
            </div>
        </section>
      </StyledAccordionsGroup>
    );
  }

const StyledAccordionsGroup = styled.section`

  .contenAccordion{
    width: inherit;
    @media(min-width: 768px){
      width: 100%;
    }
  }
`