import { useState } from "react";

export default function ModalNewProduct(props){

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [model, setModel] = useState('');
    const [code, setCode] = useState('');
    const [catId, setcatId] = useState(null);
    
    const handleSubmitProduct = (e) => {
        e.preventDefault();
        props.getContentNewProduct(name, description, model, code, catId);
    }

    return(
        <>
            {/* <!-- Button trigger modal --> */}
            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={props.idboton}>
                {props.nameButon}
            </button>

            {/* <!-- Modal --> */}
            <div className="modal fade" id={props.idmodal} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title fs-5" id="exampleModalLabel">{props.title}</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <label htmlFor='name'>Nombre: </label>
                                <input type="text" onChange={e => setName(e.target.value)}/>
                                <br />
                                <label htmlFor='description'>Descripción: </label>
                                <input type="text" onChange={e => setDescription(e.target.value)}/>
                                <br />
                                <label htmlFor="model">Modelo: </label>
                                <input type="text" onChange={e => setModel(e.target.value)}/>
                                <br />
                                <label htmlFor="code">Código: </label>
                                <input type="text" onChange={e => setCode(e.target.value)}/>
                                <br />
                                <label htmlFor="catId">Categoria que pertenece: </label>
                                <input type="number" min={1} onChange={e => setcatId(e.target.value)} />
                                <br />
                                <div className="modal-footer">
                                    <button onClick={props.action} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                                    <button onClick={handleSubmitProduct} type="button" className="btn btn-primary">Guardar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}