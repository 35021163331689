import React from 'react';
import styled from 'styled-components';
import { useWindowWidth } from '../../hooks/useWindowWidth';

export default function ProductDescription({ title, description, mainImageSrc, sliderImages, slogan }) {

    const [windowWidith] = useWindowWidth();

    return (
        <StyleProductsDescription>
            <div className="row" style={{ marginBottom: '5rem' }}>
                {/* Contenedor de la imagen del producto */}
                <div className="col-md-5 containerImgProduct">
                    <div className="imgWrapper">
                        <img src={mainImageSrc} alt="mainImageOfProduct" className="imgProduct" />
                    </div>
                </div>

                {/* Contenedor del texto de la descripción */}
                <div className="col-md-7 containerTextDescription">
                    <div className="descriptionContent mb-4 d-flex flex-column justify-content-center align-items-center">
                        <h2 className="text-center">{slogan}</h2>
                        <div
                            style={{
                                backgroundColor: '#ffffff',
                                width: '95%',
                                height: windowWidith >= 576 ? '50%' : '60%',
                            }}
                            className="mt-3 p-3 border rounded"
                        >
                            <p style={{minHeight: '4rem'}}>{description}</p>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between">
                        {sliderImages &&
                            sliderImages.map((imageSrc, index) => (
                                <div key={index} style={{width: '32.5%'}}>
                                    <img
                                        key={index}
                                        src={imageSrc}
                                        alt={`slide ${index + 1}`}
                                        className="img-fluid"
                                        style={{ width: '100%', height: 'auto' }}
                                        data-bs-toggle="modal" data-bs-target={`#img${imageSrc}`}
                                        />
                                    
                                    <div className="modal fade" id={`img${imageSrc}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                                        <div className="modal-dialog modal-xl">
                                            <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                <img src={imageSrc} alt={`slide ${index + 1}`} style={{width: '100%'}} />
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </StyleProductsDescription>
    );
}

const StyleProductsDescription = styled.div`
    .containerImgProduct,
    .containerTextDescription {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;

        @media (min-width: 768px) {
            heigth: 100%;
        }
    }

    .containerImgProduct {
        @media (min-width: 768px) {
            padding-right: 2px !important;}
        }    
    }
    .imgWrapper {
        width: auto;
        overflow: hidden; /* Oculta el contenido que exceda el contenedor */

        @media (min-width: 768px) {
            width: 100%;
            height: 99.8%;
        }
    }

    .imgProduct {
        margin-bottom: 1rem;
        width: 100%;
        height: inherit;
        object-fit: cover;
        @media (min-width: 768px) {
            margin-bottom: 0px;
        }
    }

    .descriptionContent {
        width: 100%;
        height: auto;
        padding: 1rem;
        background-color: #e3e2e1;
        @media (min-width: 1900px) {
            padding: 2.4rem;

        }
    }
`;
