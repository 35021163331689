const LoginService = {
    
    async login(data) {
        return await fetch(process.env.REACT_APP_API_URL + "/api/login", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: data.user,
                password: data.password,
            })
        })
        .then(response => response.json())
        .then(response => {
            return response
        })
        .catch(error => {
            return 'error'
        })
    },

}

export default LoginService