import styled from "styled-components";

export default function Loader(){

    return(
        <StyledLoader>
            <div className="containerLoader">
                <div className="spinner-border text-success" role="status">
                    <span className="sr-only"></span>
                </div>
                    <p className="textLoader">Cargando...</p>
            </div>
        </StyledLoader>
    );
};

const StyledLoader = styled.div`
    .containerLoader{
        text-align: center;
        width: auto;
    }
`