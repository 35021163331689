import styled from "styled-components";
import TableBarton from "../../../component/tables/TableBarton";
import Normas from "../../../component/normas/Normas";

export default function BenefitsCorrosionView() {
    return (
        <StyleBenefitsCorrosionView>
            <img className="styleImg" src='./assets/img/benefits/corrosion/bannerAnticorrosionx600.jpg' alt="Corrosión en el Hormigon"></img>
            <div className="containerBenefit">
                
                <h2>Anticorrosivo</h2>
                <img className="videoComparison" src="./assets/img/videos/video.jpg" alt="Video Anticorrosión"></img>
                <p className="textComparison">Lorem ipsum, dolor sit  amet consectetur adipisicing elit. Quos inventore consequuntur magnam impedit, nostrum vitae rerum quasi, dignissimos ullam sed accusantium pariatur consectetur fugit sequi debitis! Fugit, quibusdam consequuntur. Cum!
                Lorem ipsum, dolor sit  amet consectetur adipisicing elit. Quos inventore consequuntur magnam impedit, nostrum vitae rerum quasi, dignissimos ullam sed accusantium pariatur consectetur fugit sequi debitis! Fugit, quibusdam consequuntur. Cum!
                Lorem ipsum, dolor sit  amet consectetur adipisicing elit. Quos inventore consequuntur magnam impedit, nostrum vitae rerum quasi, dignissimos ullam sed accusantium pariatur consectetur fugit sequi debitis! Fugit, quibusdam consequuntur. Cum!</p>
            </div>
            <TableBarton/>
            <Normas/>
        </StyleBenefitsCorrosionView>
    )
}

const StyleBenefitsCorrosionView = styled.div`
    .styleImg{
        margin-top: var(--alturaNavBar);
        width: 100%;
    }
    .containerBenefit{
        padding: var(--paddinLateralMobile);
        margin-top: 3.5rem;
        @media(min-width: 768px){
            padding: var(--paddinLateral);
        }
    }
    .videoComparison{
        margin-top: 2rem;
    }
    .textComparison{
        margin-top: 2rem;
        text-align: justify;
        line-height: 2.2;
    }
`