import { useState, useEffect } from 'react';
import styled from 'styled-components';
import PriceService from "../../services/PriceService";
import CustomerService from '../../services/CustomerService';
import toThousand from '../../services/ToThousandService';

export default function CalculatingPool() {
    const [long, setLong] = useState('');
    const [broad, setBroad] = useState('');
    const [depth, setDepth] = useState('');
    const [totalLinealB6, setLinealTotalB6] = useState('');
    const [totalEnUB8, setEnUTotalB8] = useState('');
    const [totalLinealB8, setLinealTotalB8] = useState('');
    const [totalEnLB8, setEnLTotalB8] = useState('');
    const [totalB6, setTotalB6] = useState('');
    const [totalB8, setTotalB8] = useState('');
    const [dolar, setDolar] = useState([]);
    const [showResults, setShowResults] = useState(false); // Nuevo estado para controlar la visualización de resultados

    useEffect(() => {
        const getDolar = async () => {
            const dolarData = await CustomerService();
            setDolar(dolarData);
        };
        getDolar();
    }, []);

    const priceB6 = PriceService.prices[1]; // Precio solo de B6
    const priceB8 = PriceService.prices[3]; // Precio solo de B8

    const handleLongChange = (event) => {
        const newValue = Math.abs(parseFloat(event.target.value)); // Obtener el valor absoluto
        setLong(newValue);
    };

    const handleBroadChange = (event) => {
        const newValue = Math.abs(parseFloat(event.target.value)); // Obtener el valor absoluto
        setBroad(newValue);
    };

    const handleDepthChange = (event) => {
        const newValue = Math.abs(parseFloat(event.target.value)); // Obtener el valor absoluto
        setDepth(newValue);
    };

    const areInputsValid = () => {
        return long !== '' && broad !== '' && depth !== '';
    };

    const calculatePool = () => {
        const longValue = parseFloat(long);
        const broadValue = parseFloat(broad);
        const depthValue = parseFloat(depth);

        const perimeter = longValue * 2 + broadValue * 2;

        const recta6mts =
            ((depthValue / 0.2) * perimeter + (perimeter / 0.2) * depthValue) * 2;
        const recta8mts =
            ((longValue / 0.2) * broadValue + (broadValue / 0.2) * longValue) * 2;
        const u6mts = perimeter / 0.2;
        const l8mts = (perimeter / 0.2) * 2 + (4 * depthValue / 0.2 * 2);

        setLinealTotalB6(recta6mts);
        setLinealTotalB8(recta8mts);
        setEnUTotalB8(u6mts);
        setEnLTotalB8(l8mts);

        const totalB6mts = recta6mts + u6mts;
        const totalB8mts = recta8mts + l8mts;

        setTotalB6(totalB6mts);
        setTotalB8(totalB8mts);
        setShowResults(true); // Activar la visualización de resultados
    };

    return (
        <div style={{backgroundColor:'var(--colorFilaTabla'}}>
            <div className="container-fluid">
                <div className="row" style={{ paddingBottom: '5rem', marginTop: '5rem' }}>
                    <StyleCalculatingPool>
                        <h2 style={{ textAlign: 'center', color: 'var(--colorTerciario)' }}>
                            Calcula tu próximo ahorro con Barton para tu Pileta
                        </h2>
                        <h2 style={{margin: '1rem 0rem', textAlign: 'center'}}><i className="fa-solid fa-calculator"></i></h2>
                        <div className="containerImputs">
                        <label htmlFor="" className='titleTextResult' style={{fontSize: '1.5rem', fontWeight: '600', color: 'var(--colorTerciario)', marginTop: '1rem'}}>¿Que medida tiene la pileta?</label>

                            <input
                                className="form-control"
                                style={{ margin: '1rem 0rem' }}
                                type="number"
                                value={long}
                                onChange={handleLongChange}
                                placeholder="Ingrese lado A (metros)"
                            />
                            <input
                                className="form-control"
                                style={{ margin: '1rem 0rem' }}
                                type="number"
                                value={broad}
                                onChange={handleBroadChange}
                                placeholder="Ingrese lado B (metros)"
                            />
                            <input
                                className="form-control"
                                style={{ margin: '1rem 0rem' }}
                                type="number"
                                value={depth}
                                onChange={(event) => {
                                    const value = parseFloat(event.target.value);
                                    if (value > 0 && value <= 1.5) {
                                        setDepth(value);
                                    } else if (value <= 0 || isNaN(value)) {
                                        setDepth(''); // Vacía el campo si el valor no es válido
                                    }
                                }}
                                placeholder="Ingrese profundidad no máxima a 1,5 (metros)"
                                min="0.1" // Mínimo mayor a 0
                                max="1.5"  // Máximo permitido
                                step="0.1" // Incremento para valores decimales
                            />

                            <div
                                style={{
                                    marginTop: '1.5rem',
                                    textAlign: 'center',
                                    marginBottom: '1rem',
                                }}
                            >
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={calculatePool}
                                    disabled={!areInputsValid()}
                                >
                                    Calcular Metros Lineales
                                </button>
                            </div>
                        </div>
                    </StyleCalculatingPool>

                    {showResults && (
                        <StyleResult>
                        <div className="container-fluid">
                            <div className='row'>
                                <div className="containerTextResult">
                                    <div className="col-12 col-lg-4" style={{textAlign: 'center'}}>
                                        <h4 style={{marginBottom: '2rem', color: 'var(--colorTerciario)'}}>Total Barton 6</h4>
                                        <p>Total de metros Lineales Barton B6: <strong>{totalLinealB6} metros lineales</strong></p>
                                        <p>Total de metros en U Barton B6: <strong>{totalEnUB8} unidades (c/u 1mts)</strong></p>
                                        <p>Total de metros Barton B6: <strong>{totalB6} metros lineales</strong></p>
                                        <p>Precio lineal Total Dolar: <strong>{toThousand(((totalB6) * priceB6.precioLineal)) } U$S </strong></p>
                                        <p>Precio lineal Total Pesos: <strong>{toThousand(((totalB6) * priceB6.precioLineal)*dolar) } $</strong></p>
                                    </div>
                                    <div className="linea"></div>
                                    <div className="col-12 col-lg-4" style={{textAlign: 'center'}}>
                                        <h4 style={{marginBottom: '2rem', color: 'var(--colorTerciario)'}}>Total Barton 8</h4>
                                        <p>Total de metros Lineales Barton B8: <strong>{totalLinealB8} metros lineales</strong></p>
                                        <p>Total de metros en L Barton B8: <strong>{totalEnLB8} unidades (c/u 1mts)</strong></p>
                                        <p>Total de metros Barton B8: <strong>{totalB8} metros lineales</strong></p>
                                        <p>Precio lineal Total Dolar: <strong>{toThousand(((totalB8) * priceB8.precioLineal)) } U$S </strong></p>
                                        <p>Precio lineal Total Pesos: <strong>{toThousand(((totalB8) * priceB8.precioLineal)*dolar) } $</strong></p>
                                    </div>
                                    <div className="linea"></div>
                                    <div className="col-12 col-lg-4 containerResultTotal" style={{textAlign: 'center'}}>
                                        <h4 style={{marginBottom: '2rem', color: 'var(--colorTerciario)'}}>Total</h4>
                                        <p>Total de metros lineales de Barton: <strong>{totalB6 + totalB8} metros lineales</strong></p>
                                        <p>Precio lineal Total Dolar: <strong>{toThousand(((totalB6) * priceB6.precioLineal)+((totalB8) * priceB8.precioLineal))} U$S </strong></p>
                                        <p>Precio lineal Total Pesos: <strong>{toThousand((totalB6 * priceB6.precioLineal * dolar )+(totalB8 * priceB8.precioLineal * dolar)) } $</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </StyleResult>
                    )}
                </div>
            </div>
        </div>
    );
}

const StyleCalculatingPool = styled.div`
    .containerImputs {
        @media (min-width: 768px) {
            width: 30%;
            margin: 0 auto;
        }
    }
`

const StyleResult = styled.div`
    margin-top: 3rem;
    .containerTextResult{
        border: 1px solid var(--colorFilaTabla);
        border-radius: 0.5rem;
        padding: 2rem 1rem;
        width: 100%;
        background-color: #FFF;
        @media (min-width: 768px) {
            display: flex;
        }
    }
    .containerResultTotal {
        background-color: rgba(25, 135, 85, 0.2);
        border-radius: 5px;
        padding: 1.3rem;
    }
    .linea {
        margin: 3rem auto;
        border: 1px solid var(--colorSecundario);
        width: 80%;
        @media (min-width: 768px) {
            display: none;
        }
    }
`