import styled from "styled-components";
import TableBarton from "../../../component/tables/TableBarton";
import Normas from "../../../component/normas/Normas";
import Documentation from "../../../component/documentation/Documentation";

export default function BenefitsResistenteView() {
    return (
        <StyleBenefitsResistenteView>
            <img className="styleImg" src='./assets/img/benefits/resistente/bannerResistentex400.jpg' alt="3 veces mas resistente"></img>
            <div className="containerBenefit">
                
                <h2>3 veces más Resistente</h2>
                <iframe className=""
                        title="Video Home"
                        src={"https://player.vimeo.com/video/826722020?controls=0?autoplay=1&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1"}  
                        frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen>
                </iframe>
                <p className="textComparison">Lorem ipsum, dolor sit  amet consectetur adipisicing elit. Quos inventore consequuntur magnam impedit, nostrum vitae rerum quasi, dignissimos ullam sed accusantium pariatur consectetur fugit sequi debitis! Fugit, quibusdam consequuntur. Cum!
                Lorem ipsum, dolor sit  amet consectetur adipisicing elit. Quos inventore consequuntur magnam impedit, nostrum vitae rerum quasi, dignissimos ullam sed accusantium pariatur consectetur fugit sequi debitis! Fugit, quibusdam consequuntur. Cum!
                Lorem ipsum, dolor sit  amet consectetur adipisicing elit. Quos inventore consequuntur magnam impedit, nostrum vitae rerum quasi, dignissimos ullam sed accusantium pariatur consectetur fugit sequi debitis! Fugit, quibusdam consequuntur. Cum!</p>
            </div>
            <Documentation/>
            <TableBarton/>
            <Normas/>
        </StyleBenefitsResistenteView>
    )
}

const StyleBenefitsResistenteView = styled.div`
    .styleImg{
        margin-top: var(--alturaNavBar);
        width: 100%;
    }
    .containerBenefit{
        padding: var(--paddinLateralMobile);
        margin-top: 3.5rem;
        @media(min-width: 768px){
            padding: var(--paddinLateral);
        }
    }
    .videoComparison{
        margin-top: 2rem;
    }
    .textComparison{
        margin-top: 2rem;
        text-align: justify;
        line-height: 2.2;
    }
`