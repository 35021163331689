import React from 'react';
import styled from 'styled-components';

export default function ImageWithTextButton({src, text, buttonText, height, width ,onclick }) {
    return (
        <ImageContainer>
            <OverlayContent>
                <OverlayText>{text}</OverlayText>
                <OverlayButton onClick={onclick}>{buttonText}</OverlayButton>
            </OverlayContent>
            <StyledImage height={height} width={width} src={src} alt="Imagen de ejemplo" />
        </ImageContainer>
    );
}

const ImageContainer = styled.div`
    position: relative;
    margin-top: 2%;
    width: 100%; /* Ajusta el ancho según tus necesidades */
    height: 300px; /* Ajusta la altura según tus necesidades */
`;

const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const OverlayContent = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
`;

const OverlayText = styled.p`
    color: white;
    font-size: 1.44rem;
    font-weight: bold;
    margin: 0 0 15% 0; /* Espacio debajo del texto */
`;

const OverlayButton = styled.button`
    padding: 10px 20px;
    background-color: #28a745; /* Color del botón */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;

    &:hover {
        background-color: #218838; /* Color al pasar el mouse */
    }
`;
