import BenefitsGrup from '../../component/benefit/BenefitGrup';
import TagsGrup from '../../component/tags/TagsGrup';
import DivisionImg from '../../component/divisionImg/DivisionImg';
import Documentation from '../../component/documentation/Documentation';
import TableBarton from '../../component/tables/TableBarton';
import VideoHomeSimple from '../../component/videoHome/VideoHomeSimple';
import Prodructs from '../../component/products/Products';
import {listBenefits, listProducts, listTag} from '../../mocks/mock.js';

function HomeView() {
    return (
        <section>
            <VideoHomeSimple/>

            <BenefitsGrup listBenefits={listBenefits}/>

            <DivisionImg imageUrl="assets/img/divisionBanner/divisionHome1.jpg"/>

            <Prodructs listProducts={listProducts.slice(1)}/>

            <div style={{backgroundColor: "var(--colorFondoMain)", padding: '7rem 0rem'}}>
                <TableBarton/>
            </div>
            
            <TagsGrup listTag={listTag}/>

            <Documentation/>
        </section>
    )
};

export default HomeView;