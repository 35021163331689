function WorksView() {
    return (
        <section>
            <div>Obras</div>
            <div>Obras</div>
            <div>Obras</div>
            <div>Obras</div>
            <div>Obras</div>
            <div>Obras</div>
            <div>Obras</div>
        </section>
    )
}
export default WorksView;