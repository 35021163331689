import './App.css';
import { Route, Routes } from 'react-router-dom';
import LayoutSimple from './layouts/variations/LayoutSimple';
import LayoutDashboard from './layouts/variations/LayoutDashboard';

//VISTAS DE DASHBOARD
import DashboardView from './views/dashboard/DashboardView';
import BenefitsDashboardView from './views/dashboard/BenefitsDashboardView';
import ProductsDashboardView from './views/dashboard/ProductsDashboardView';
import SolutionsDashboardView from './views/dashboard/SolutionsDashboardView';
import WorksDashboardView from './views/dashboard/WorksDashboardView';

//VISTAS DE LANDING
import HomeView from './views/landing/HomeView';
import SolutionsView from './views/landing/SolutionsView.jsx';
import ProductsView from './views/landing/ProductsView';
import WorksView from './views/landing/WorksView';
import LoginView from './views/landing/LoginView';
import UsView from './views/landing/UsView';
import ContactView from './views/landing/ContactView';
import BenefitsCorrosionView from './views/landing/benefits/BenefitsCorrosionView';
import BenefitsLivianoView from './views/landing/benefits/BenefitsLivianoView';
import BenefitsTransportableView from './views/landing/benefits/BenefitsTrasportableView';
import BenefitsResistenteView from './views/landing/benefits/BenefitsResistenteView';
import SolutionInfraestructurasView from './views/landing/solution/SolutionInfraestructurasView';
import SolutionPisosIndustriales from './views/landing/solution/SolutionPisosIndustrialesView';
import SolutionPlateas from './views/landing/solution/SolutionPlateasView';
import SolutionMuelles from './views/landing/solution/SolutionMuellesView';
import SolucionPremoldeados from './views/landing/solution/SolutionPremoldeadoView';
import SolucionHidroelectricas from './views/landing/solution/SolutionHidroelectricasView';
import SolucionIcf from './views/landing/solution/SolutionIcfView';
import SolucionElectrica from './views/landing/solution/SolutionObrasElectricasView';
import SolutionPlantasIndustriales from './views/landing/solution/SolutionPlantasIndustrialesView';
import SolutionPiletas from './views/landing/solution/SolutionPiletasView.jsx';
import SucessCasesView from './views/landing/SuccessCasesView';

import ApplicationView from './views/landing/solution/ApplicationView.jsx';

export default function App() {
    return (
        <Routes>
            
            {/* RUTAS PARA FRONT CLIENTE */}
            <Route path="/" element={<LayoutSimple />}>
                <Route index element={<HomeView />} />
                <Route path="soluciones" element={<SolutionsView />} />
                <Route path="casosDeExito" element={<SucessCasesView />} />
                <Route path="productos" element={<ProductsView />} />
                <Route path="aplicaciones" element={<ApplicationView />} />
                <Route path="obras" element={<WorksView />} />
                <Route path="nosotros" element={<UsView />} />
                <Route path="contacto" element={<ContactView />} />
                <Route path="login" element={<LoginView />} />

                {/* RUTAS DE BENEFICIOS  */}
                <Route path='beneficioAnticorrosion' element={<BenefitsCorrosionView/>} />
                <Route path='beneficioLiviano' element={<BenefitsLivianoView/>} />
                <Route path='beneficioTransportable' element={<BenefitsTransportableView/>} />
                <Route path='beneficioResistente' element={<BenefitsResistenteView/>} />

                {/* RUTA DE APLICACIONES */}
                {/* <Route path='aplicaciones' element={<ApplicationView/>}/> */} {/*//* VER SI SE IMPLEMENTA EL GENERICO */}
                <Route path='solucionPiletas' element={<SolutionPiletas />} />
                <Route path='solucionInfraestructuras' element={<SolutionInfraestructurasView/>}/>
                <Route path='solucionPisosIndustriales' element={<SolutionPisosIndustriales/>}/>
                <Route path='solucionPlateas' element={<SolutionPlateas/>}/>
                <Route path='solucionMuelles' element={<SolutionMuelles/>}/>
                <Route path='solucionPremoldeados' element={<SolucionPremoldeados/>}/>
                <Route path='solucionHidroelectricas' element={<SolucionHidroelectricas/>}/>
                <Route path='solucionIcf' element={<SolucionIcf/>}/>
                <Route path='solucionElectrica' element={<SolucionElectrica/>}/>
                <Route path='solucionPlantasIndustriales' element={<SolutionPlantasIndustriales/>}/>
            </Route>

            {/* RUTAS PARA FRONT ADMINISTRADOR */}
            <Route path="/admin" element={<LayoutDashboard />}>
                <Route index element={<DashboardView />} />
                <Route path="productos" element={<ProductsDashboardView />} />
                <Route path="beneficios" element={<BenefitsDashboardView />} />
                <Route path="soluciones" element={<SolutionsDashboardView />} />
                <Route path="obras" element={<WorksDashboardView />} />
            </Route>
        </Routes>
    );
}