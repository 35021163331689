import styled from "styled-components";
import TableBarton from "../../../component/tables/TableBarton";
import Normas from "../../../component/normas/Normas";

export default function BenefitsLivianoView() {
    return (
        <StyleBenefitsLivianoView>
            <img className="styleImg" src='./assets/img/benefits/liviano/bannerLivianox400.jpg' alt="7 veces mas liviano que el Hirro"></img>
            <div className="containerBenefit">
                
                <h2>7 veces mas liviano que el hierro</h2>
                <img className="videoComparison" src="./assets/img/videos/video.jpg" alt="Video Liviano"></img>
                <p className="textComparison">Lorem ipsum, dolor sit  amet consectetur adipisicing elit. Quos inventore consequuntur magnam impedit, nostrum vitae rerum quasi, dignissimos ullam sed accusantium pariatur consectetur fugit sequi debitis! Fugit, quibusdam consequuntur. Cum!
                Lorem ipsum, dolor sit  amet consectetur adipisicing elit. Quos inventore consequuntur magnam impedit, nostrum vitae rerum quasi, dignissimos ullam sed accusantium pariatur consectetur fugit sequi debitis! Fugit, quibusdam consequuntur. Cum!
                Lorem ipsum, dolor sit  amet consectetur adipisicing elit. Quos inventore consequuntur magnam impedit, nostrum vitae rerum quasi, dignissimos ullam sed accusantium pariatur consectetur fugit sequi debitis! Fugit, quibusdam consequuntur. Cum!</p>
            </div>
            <TableBarton/>
            <Normas/>
        </StyleBenefitsLivianoView>
    )
}

const StyleBenefitsLivianoView = styled.div`
    .styleImg{
        margin-top: var(--alturaNavBar);
        width: 100%;
    }
    .containerBenefit{
        padding: var(--paddinLateralMobile);
        margin-top: 3.5rem;
        @media(min-width: 768px){
            padding: var(--paddinLateral);
        }
    }
    .videoComparison{
        margin-top: 2rem;
    }
    .textComparison{
        margin-top: 2rem;
        text-align: justify;
        line-height: 2.2;
    }
`