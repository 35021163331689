import { Outlet } from 'react-router-dom';
import Sidenav from '../partials/Sidenav';
import styled from 'styled-components';

const LayoutDashboard = () => {
    return(
        <StyleLayouDashboard>
            <Sidenav />
            <Outlet />
        </StyleLayouDashboard>
    )
}

export default LayoutDashboard;

const StyleLayouDashboard = styled.section`
    display: grid;
    grid-template-columns: 15% 80%;
`