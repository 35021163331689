import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { HashLink } from 'react-router-hash-link';
import BurguerButton from "../../component/buttons/BurguerButton";

export default function Header() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [clicked, setClicked] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [headerBackground, setHeaderBackground] = useState("var(--colorTerciario)");  //usar "transparent" para volver el efecto original

  const location = useLocation();
  const isHomePage = location.pathname === "/" || location.pathname === "/#home";

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    const handleScroll = () => {
      setScrollPosition(window.scrollY);

      // Actualizar el fondo del encabezado cuando se cumplan las condiciones
      if (
        (windowWidth >= 768 && window.scrollY >= 200) ||
        (windowWidth < 768 && (window.scrollY >= 200 || clicked)) ||
        !isHomePage
      ) {
        setHeaderBackground("var(--colorTerciario)");
      } else {
        setHeaderBackground("var(--colorTerciario)"); //usar "transparent" para volver el efecto original
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [windowWidth, clicked, isHomePage]);

  const handleClick = () => {
    setClicked(!clicked);
    if (!clicked) {
      document.body.style.overflow = "hidden";
      setHeaderBackground("var(--colorTerciario)"); // Cambia el color de fondo al hacer clic en el botón burger.
    } else {
      document.body.style.overflow = "auto";
      if (windowWidth >= 768 && scrollPosition >= 200) {
        setHeaderBackground("var(--colorTerciario)");
      } else if ((windowWidth < 768 && scrollPosition >= 200) || !isHomePage) {
        setHeaderBackground("var(--colorTerciario)"); // Cambiar a color en la versión móvil cuando se cierra el botón burger
      } else {
        setHeaderBackground("transparent");
      }
    }
  };

  const handleMenuItemClick = () => {
    setClicked(false);
    document.body.style.overflow = "auto";
    setHeaderBackground((windowWidth >= 768 && scrollPosition >= 200) ? "var(--colorTerciario)" : "transparent");
  };

  return (
    <HeadesStyle backgroundColor={headerBackground}>
      {windowWidth >= 992 ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">

              <div id="header" className={`contentNavBar ${clicked ? "active" : ""}`}>
                <HashLink className={`links ${clicked ? "active" : ""}`} to="/#home"><img src="./assets/img/logo-sin-r2.png" alt="Logo Barton" /> </HashLink>
                <div className='containerLinks'>
                  <HashLink className={`links ${clicked ? "active" : ""}`} to="/casosDeExito">Casos de Éxito</HashLink>
                  <HashLink className={`links ${clicked ? "active" : ""}`} to="/productos">Productos</HashLink>
                  <HashLink className={`links ${clicked ? "active" : ""}`} to="/#aplicaciones">Aplicaciones</HashLink>
                  <HashLink className={`links ${clicked ? "active" : ""}`} to="/#documentacion">Certificaciones</HashLink>
                  <HashLink className={`links ${clicked ? "active" : ""}`} to="/#contacto">Contacto</HashLink>
                  
                </div>
              </div>

            </div>
          </div>
        </div>
      ) : (
        <>
        <div className="containerBurger">
          <div className="container-fluid" style={{display: 'flex', justifyContent: 'space-between', marginTop: '0.8rem'}}>
            <HashLink to='/#home'><img style={{width: '150px'}} src="../../../assets/img/logo-sin-r2.png" alt='Logo Barton'/></HashLink>
            <BurguerButton clicked={clicked} handleClick={handleClick}/>
          </div>
        </div>
        {clicked && (
          <div className="burgerMenu">
              <ul className="container-fluid">
                  <li><HashLink className='itemMenuBurger' to='/#home' onClick={handleMenuItemClick}><i className="fa-solid fa-house"></i> Inicio</HashLink></li>
                  <li><HashLink className='itemMenuBurger' to='/casosDeExito' onClick={handleMenuItemClick}><i className="fa-solid fa-person-digging"></i> Casos de Éxito</HashLink></li>
                  <li><HashLink className='itemMenuBurger' to='/productos' onClick={handleMenuItemClick}><i className="fa-solid fa-building"></i> Productos</HashLink></li>
                  <li><HashLink className='itemMenuBurger' to='/#aplicaciones' onClick={handleMenuItemClick}><i className="fa-solid fa-arrow-trend-up"></i> Aplicaciones</HashLink></li>
                  <li><HashLink className='itemMenuBurger' to='/#documentacion' onClick={handleMenuItemClick}><i className="fa-solid fa-file-lines"></i> Certificaciones</HashLink></li>
                  <li><HashLink className='itemMenuBurger' to='/#contacto' onClick={handleMenuItemClick}><i className="fa-solid fa-credit-card"></i> Contacto</HashLink></li>
              </ul>
          </div>
        )}
      </>
      )}
    </HeadesStyle>
  );
}


const HeadesStyle = styled.header`
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1000;
    background-color: ${({ backgroundColor }) => backgroundColor};
    transition: background-color 0.3s ease;
    height: var(--alturaNavBarMobile);
    @media (min-width: 1200px) {
      height: var(--alturaNavBar);
    }
    .contentNavBar {
      display: none;
      @media (min-width: 992px) {
        align-items: center;
        // display: grid;
        // grid-template-columns: 2fr 1fr;
        display: flex;
        height: 100%;
        justify-content: space-between;
      }
    }
    .contentNavBar img{
      display: none;
      @media (min-width: 992px) {
          display: block;
          width: 150px;
      }
      @media (min-width: 1200px) {
          width: 180px;
      }
    }
    img{
      @media (min-width: 992px){
        width: 250px;
      }
    }
    .containerLinks{
      @media (min-width: 992px){
        display: flex;
        justify-content: flex-start;
      }
    }

    .contentNavBar.active {
      background-color: var(--colorTerciario);
      width: 100%;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      display: block;
      top: var(--alturaNavBar);
      left: 0;
      right: 0;
      text-align: center;
    }

    .links {
      display: block;
      font-size: 2rem;
      border-bottom: 3px solid var(--colorSecundario);
      font-family: var(--fuenteSecundaria);
      @media (min-width: 992px) {
        border: none;
        color: #fff;
        display: flex;
        font-size: 1.2rem;
        padding: 10px;
        width: 10rem;
        justify-content: center;
      }
        @media (min-width: 1200px) {
          width: 12rem;
          font-size: 1.4rem;
        }
    }
    .especial{
      color: var(--colorSecundario)!important; 
      font-weight: 900!important;
    }
    span{
      color: #fff;
      font-family: var(--fuenteSecundaria);
      font-size: 2rem;
      font-weight: 300;
    }
    .links.active {
      background-color: var(--colorTerciario);
      color: #fff;
      padding: 2.5rem;
    &:hover {
      background-color: var(--colorSecundario);
      color: var(--colorTextos);
      }
    }

    .containerBurger{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .burgerMenu{
      width: auto;
      height: 100vh;
      background-color: ${({ backgroundColor }) => backgroundColor};
      transition: background-color 0.3s ease;
      padding-top: 2rem;
    }
    .itemMenuBurger{
      color: #fff;
      font-family: var(--fuenteSecundaria);
      font-size: 1.2rem;
      font-weight: 300;
      border-bottom: transparent solid 2px;
    }
    .itemMenuBurger:hover{
      color: #fff;
      
      border-bottom: var(--colorSecundario) solid 2px;
    }
    i{
      font-size: 1.2rem;
    }
    ul li{
      margin: 1.2rem 0.2rem;
    }
`
