import styled from "styled-components"
// import BannerPage from "../../component/banner/BannerPage"
import TagsGrupComplet from "../../component/tags/TagsGrupComplet.jsx";

// mocks
import {listTagSolution} from "../../mocks/mock.js";

export default function SolutionsView() {
    return (
        <StyledSolutionView>
            {/* <BannerPage datosBanner={datosBanner}/> */}
            <div className="containerSolutions">
                <TagsGrupComplet listTag={listTagSolution}/>
            </div>
        </StyledSolutionView>
    )
}


// const datosBanner = [
//     {img: './assets/img/divisionBanner/divisionHome1.jpg'}
// ]

const StyledSolutionView = styled.div`
    margin-top: var(--alturaNavBar);
    .containerSolutions{
        padding: var(--paddinLateralMobile);
        /* @media(min-width: 768px){
            padding: var(--paddinLateral)
        } */
    }
    section h3{
        
    }
`