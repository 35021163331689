export default function UsView() {
    return (
        <section>
            <div>Nosotros</div>
            <div>Nosotros</div>
            <div>Nosotros</div>
            <div>Nosotros</div>
            <div>Nosotros</div>
            <div>Nosotros</div>
            <div>Nosotros</div>
        </section>
    )
}