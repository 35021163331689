import ProductCard from "./ProductCard"

export default function Prodructs (props){    
    return (
        <div className="containerBarton" style={{margin: '6rem auto'}}>
            <div className="row">
                <h2 style={{textAlign: "center", marginBottom: '2rem', color: 'var(--colorTerciario)'}}></h2>
                {props.listProducts.map((data) => (
                    <div className="col-6 col-lg-4" key={data.id} >
                        <ProductCard key={data.id} cardInfo={data}/>
                    </div>
                ))}
            </div>
        </div>
    )
}