import styled from "styled-components";

export default function Comparison(props){

    return(
        <StyleComparison>
            <div className="containerVideo">
                <iframe className="video"
                        title={props.comparisonInfo.titleVideo}
                        src={props.comparisonInfo.video}  
                        frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen>
                </iframe>
                <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
        </StyleComparison>
    )
}

const StyleComparison = styled.div`


.containerVideo{
        //margin-top: 1rem;
        min-width: 100%;
        height: 100%;
        
        @media (min-width: 768px){
            width: 650px;
            height: 600px;
        }
    }
    .video{
        width: 100%;
        height: 100%;    
        @media (min-width: 768px){
            margin: 0px;
            width: 100%;
            height: 100%;
        }
    }
`