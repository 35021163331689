import styled from "styled-components";

export default function Accordion(props) {
  return (
    <StyledAccordion>            
        <div className="accordion-item accordion" id={'heading'+props.cardInfo.id}>
            <h2 className="accordion-header" >
                <button className="accordion-button collapsed" 
                        type="button" 
                        data-bs-toggle="collapse" 
                        data-bs-target={"#flush-collapseOne"+props.cardInfo.id} 
                        aria-expanded="false" 
                        aria-controls={"flush-collapseOne"+props.cardInfo.id}>
                    {props.cardInfo.title}
                    {props.cardInfo.logo && (
                      <img className="logoNorma" src={props.cardInfo.logo} alt="Logo de Norma" />  
                    )}
                </button>
            </h2>
            <div id={"flush-collapseOne"+props.cardInfo.id} className="accordion-collapse collapse" 
                    aria-labelledby={"flush-headingOne"+props.cardInfo.id} 
                    data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    <img className="logoPDF" src="./assets/img/pdf.png" alt="logoPDF"></img>
                    <a href={props.cardInfo.donwload} target="_blank" rel="noreferrer noopener">{props.cardInfo.info}</a>
                </div>
            </div>
        </div>
    </StyledAccordion>
  );
}

const StyledAccordion = styled.div`
    .accordion-button:not(.collapsed){
        background-color: var(--colorTerciario);
        color: #fff;
        box-shadow:none;
        font-family: var(--fuenteSecundaria); //revisar si usar fuente primiraria o no 
    }
    .accordion-button:not(.collapsed)::after{
        filter: brightness(0%) invert(100%);
    }
    .accordion-button:focus {
        box-shadow: 0 0 0 0.25rem rgba(0, 119, 6, 0.25);
    }
    .accordion-button{
        font-size: 1.2rem;
        font-family: var(--fuenteSecundaria); //revisar si usar fuente primiraria o no 
    }
    .accordion-button .collapsed{
        min-width: auto;
    }
    .accordion-header{
    }
    @media(min-width: 768px){
        width: 100%;
    }
    .logoPDF{
        width: 40px;
        margin: 0px 10px
    }
    a{
        font-family: var(--fuenteSecundaria);
    }
    .logoNorma{
        width: 2.8rem;
        border-radius: 10rem;
        margin-left: 2rem;
    }
`