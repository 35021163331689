import { Component } from "react";
import styled from "styled-components";
import BartonService from "../../../services/BartonService";
import ModalNewProduct from "../../../component/modal/ModalNewProduct";
//import ModalEditProduct from "../../../component/modal/ModalEditProduct";
import Loader from "../../../component/loader/Loader";

export default class ProductsView extends Component {
    constructor(props){
        super (props);
        this.state={
            products: [],
            isLoading: true,
        }
    }

    componentDidMount(){
        this.searchProductsAll()
    }

    async searchProductsAll () {
        const response = await BartonService.searchProductsAll()
        this.setState({
            products: response.data,
            isLoading: false,
        })
    }

    handleSubmit = async (name, description, model, code, categoryId) => {
        this.setState ({ isLoading: true });
        const response = await BartonService.productNew (name, description, model, code, categoryId);
        if (response.status === 200) { // Verifica si la respuesta es exitosa
            console.log('cargo correcto categoria nueva') // Cambia el estado de modalNew a false para cerrar la ventana modal
        }
        this.searchProductsAll()
        this.setState({ isLoading: false });
    }

    render(){
        const {isLoading} = this.state
        return(
            <StyleProductsView>
                {isLoading ? (<Loader/>) : (
                <>
                    <table className="table tableProduct">
                        <thead>
                            <tr>
                                <th className="styleRowTitleProduct">Nombre</th>
                                <th className="styleRowTitleProduct">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.state.products.map(product => {
                                return(
                                    <tr key={product.id}>
                                        <td><p key={product.id}>{product.name}</p></td>
                                        <td className="styleRowsButtonProduct">
                                            {/* <ModalEditProduct name='Editar'></ModalEditProduct> */}
                                            <button onClick={() => this.deleteProduct(product.id)} className="btn btn-danger">eliminar</button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="styleRowNewProduct">
                        <ModalNewProduct getContentNewProduct={this.handleSubmitNewProduct} isOpen={this.state.modalNewProduct}  idboton= '#modalNewProduct' idmodal='modalNewProduct' title='Agregar Producto Nuevo' nameButon='Agregar Producto Nuevo'/>
                    </div>
                </>
                )}
            </StyleProductsView>
        )
    }
}

const StyleProductsView = styled.section`
    .tableProduct{
        width: 35rem;
    }
    .styleRowTitleProduct{
        text-align: center;
    }
    .styleRowsButtonProduct{
        text-align: end;
    }
    .styleRowNewProduct{
        text-align: center;
    }
`