import styled from "styled-components";

export default function VideoHomeSimple(){
    return(
        <StyleVideoHomeSimple>
            <div className="homeslide">
                <div className="contenLogo">
                    <div className="logos">
                        <img src="/assets/img/leed.svg" alt="Logo Leed Green Building" />
                    </div>
                </div>
                <div className="background-video">
                    <iframe
                        title="Video Home"
                        src={"https://player.vimeo.com/video/829485591?controls=0?&autopause=false&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1"}
                        frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen>
                    </iframe>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                </div>
            </div>
        </StyleVideoHomeSimple>
    )
}

const StyleVideoHomeSimple = styled.div`
    .homeslide{
        width: 100%;
        overflow: hidden;
        height: 440px;
        position: relative;
        @media (min-width: 576px) {
            height: 440px;
        }
        @media (min-width: 768px) {
            height: 600px;
        }
        @media (min-width: 992px) {
            height: 740px;
        }
        @media (min-width: 1200px){
            height: 800px;
        }
    }
    .opacity{
        background-color: rgba(0, 0, 0, 0.1);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
    }
    .contenSlogan {
        display: none;
        @media (min-width: 768px){
            background-color: rgba(255,255,255,0.2);
            border-radius: 0rem 0.4rem 0.4rem 0rem;
            position: absolute;
            text-align: end;
            z-index: 5;
            display: flex;
            justify-content: flex-stard;
            flex-direction: column;
            padding: 1.5rem 0rem;;
            bottom: 35%;
        }
    }
    .slogan{
        display: flex;
        flex-wrap: nowrap;
        @media (min-width: 768px){
            padding: 1rem;
        }
        @media(min-width: 1200px){
            padding: 1rem 3rem;

        }
        @media(min-width: 1400px){
            padding: 1rem 4rem;

        }
    }
    .slogan img{
        @media (min-width: 768px){
            width: 2rem;
        }
        @media (min-width: 1200px){
            width: 3rem;
        }
    }
    .slogan h3{
        color: var(--colorSecundario);
        margin: 0rem 5rem 0rem 1.5rem;
        @media (min-width: 768px){
            font-size: 2rem;
        }
        @media (min-width: 1200px){
            font-size: 2.5rem;
        }
    }
    .contenLogo {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: end;
        z-index: 3;
        display: flex;
        justify-content: flex-start;
    }
    .logos{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 5rem;
        margin: 1rem;
        @media (min-width: 576px) {
            width: 5rem;
        }
        @media (min-width: 768px) {
            margin: 2rem;
            width: 7rem;
        }
        @media(min-width: 992px){
            width: 9rem;

        }
    }
    .background-video{
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        width: 150%;
        height: 150%;
        -o-object-fit: cover;
        object-fit: cover;
        margin-top: var(--alturaNavBarMobile);
        @media (min-width: 768px) {
            margin-top: var(--alturaNavBar);
        }
    }
    iframe{
        position: absolute;
        width: 150%;
        height: 150%;
        margin-top: -320px;
        @media (min-width: 576px) {
            margin-top: -235px;
        }
        @media (min-width: 768px) {
            height: 110%;
            margin-top: -200px;
        }
        @media (min-width: 992px) {
            height: 80%;
            margin-top: -80px;
        }
        @media (min-width: 1200px){
            height: 90%;
            margin-top: -220px;
        }
        @media (min-width: 1400px){
            //margin-top: -200px;
        }
    }
`