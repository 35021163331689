import { Link } from "react-router-dom";
import styled from "styled-components";

export default function Sidenav(props) {
    return(
        <StyleSidenav>
                <ul>
                    <li>
                        <Link className="link" to='/admin/productos'>Productos</Link>
                        <Link className="link" to='/admin/obras'>Obras</Link>
                        <Link className="link" to='/admin/soluciones/'>Soluciones</Link>
                        <Link className="link" to='/admin/beneficios/'>Benedicios</Link>
                        <Link className="link" to='/'>Salir</Link>
                    </li>
                </ul>
        </StyleSidenav>

    )
};

const StyleSidenav = styled.ul`
    background-color: var(--colorTerciario);
    width: auto;
    height: 100vh;

    li{
        display: flex;
        flex-direction: column;
    }
    .link{
        color: #fff;
        margin: 20px;
    }
    .subMenu{
        background-color: var(--colorTerciario);
        display: block;
    }
    .subLink{
        color: #fff;
        margin: 10px;
    }
`