const BartonService = {

    async nuevoPrueba() {
        return await fetch(process.env.REACT_APP_API_URL + "/api/barton/ad/category", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token"),
            },
        })
        .then(response => response.json())
        .then(response => {
            return response
        })
        .catch(error => {
            return 'error'
        })
    },

    //SERVICIOS PARA PRODUCTOS

    //Este trae todos los productos inclusive los eliminados
    async searchProductsAllIncludDelete() {
        return await fetch(process.env.REACT_APP_API_URL + "product?all=true", {
            method: 'GET',
            headers: {
                'Authorization': process.env.REACT_APP_API_TOKEN,
            }
        })
        .then(response => response.json())
        .then(response => {
            return response
        })
        .catch(error => {
            return 'error'
        })
    },

    //Este trae todos los productos
    async searchProductsAll() {
        return await fetch(process.env.REACT_APP_API_URL + "product", {
            method: 'GET',
            headers: {
                'Authorization': process.env.REACT_APP_API_TOKEN,
                'Content-Type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(response => {
            return response
        })
        .catch(error => {
            return 'error'
        })
    },

    //Este crea un producto nuevo
    async productNew(name, description, model, code, categoryId) {
        return await fetch(process.env.REACT_APP_API_URL + "product", {
            method: 'POST',
            headers: {
                'Authorization': process.env.REACT_APP_API_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, description, model, code, categoryId })
        })
        .then(response => response.json())
        .then(response => {
            return response
        })
        .catch(error => {
            return 'error'
        })
    },

    //Elimina un producto
    async deleteProduct(idProduct) {
        return await fetch(process.env.REACT_APP_API_URL + "product/" + idProduct, {
            method: 'DELETE',
            headers: {
                'Authorization': process.env.REACT_APP_API_TOKEN,
                'Content-Type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(response => {
            return response
        })
        .catch(error => {
            return 'error'
        })
    },

    // SERVICIOS PARA CATEGORIAS
    async searchCategorysAll() {
        return await fetch(process.env.REACT_APP_API_URL + "category", {
            method: 'GET',
            headers: {
                'Authorization': process.env.REACT_APP_API_TOKEN,
                'Content-Type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(response => {
            return response
        })
        .catch(error => {
            return 'error'
        })
    },

    async searchCategory(idProduct) {
        return await fetch(process.env.REACT_APP_API_URL + "category/" + idProduct, {
            method: 'GET',
            headers: {
                'Authorization': process.env.REACT_APP_API_TOKEN,
                'Content-Type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(response => {
            return response
        })
        .catch(error => {
            return 'error'
        })
    },

    async categoryNew(name, description) {
        try {
          const response = await fetch(process.env.REACT_APP_API_URL + "category", {
            method: 'POST',
            headers: {
              'Authorization': process.env.REACT_APP_API_TOKEN,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, description })
          });
      
          if (response.ok) {
            const data = await response.json();
            return data;
          } else {
            throw new Error('La petición falló');
          }
        } catch (error) {
          console.error(error);
          return { error: 'Ocurrió un error al crear la categoría' };
        }
    },

    async categoryEdit( idProduct, name, description) {
        return await fetch(process.env.REACT_APP_API_URL + "category/" + idProduct, {
            method: 'PUT',
            headers: {
                'Authorization': process.env.REACT_APP_API_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, description })
        })
        .then(response => response.json())
        .then(response => {
            return response
        })
        .catch(error => {
            return 'error'
        })
    },

    async deleteCategory(idCategory) {
        return await fetch(process.env.REACT_APP_API_URL + "category/" + idCategory, {
            method: 'DELETE',
            headers: {
                'Authorization': process.env.REACT_APP_API_TOKEN,
                'Content-Type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(response => {
            return response
        })
        .catch(error => {
            return 'error'
        })
    },

    //Este trae todas las categorias inclusive las eliminadas
    async searchCategorysAllIncludDelete() {
        return await fetch(process.env.REACT_APP_API_URL + "category?all=true", {
            method: 'GET',
            headers: {
                'Authorization': process.env.REACT_APP_API_TOKEN,
                'Content-Type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(response => {
            return response
        })
        .catch(error => {
            return 'error'
        })
    },
};

export default BartonService;