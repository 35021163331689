// import { type } from "@testing-library/user-event/dist/type";

export const listProducts = [
    {id: 1, title: "Barton 1000", slogan: 'Barton 1000',  link: "/productos", img: "../../../assets/img/products/barra.jpg", img2: "../../../assets/img/products/rollo3.jpg", img3: "../../../assets/img/products/barra3.jpg", img4: "../../../assets/img/products/barra2.jpg", description: "Con Barton 1000 logrará reemplazar los refuerzos de acero en una gran variedad de aplicaciones por una opción de alto desempeño, que logra cumplir con los requisitos de las más estrictas normas internacionales a un costo competitivo."},
    {id: 2, title: "Barras", slogan: '',  link: "/productos", img: "../../../assets/img/products/barra.jpg", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer non consectetur turpis. Sed facilisis purus id urna tincidunt, id ullamcorper mauris ultricies. Aliquam facilisis"},
    {id: 3, title: "Rollos", slogan: '',  link: "/productos", img: "../../../assets/img/products/rollo.jpg", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer non consectetur turpis. Sed facilisis purus id urna tincidunt, id ullamcorper mauris ultricies. Aliquam facilisis"},
    {id: 4, title: "Mallas", slogan: 'Desde 50x50 hasta 150x150',  link: "/productos#mallas", img: "../../../assets/img/products/mallas.jpg", img2: "../../../assets/img/products/mallas2.jpg", img3: "../../../assets/img/products/mallas3.jpg", img4: "../../../assets/img/products/mallas4.jpg", description: "Facilidad en la colocación, bajo peso, resistencia a las inclemencias del tiempo y variedad de medidas son algunos de los beneficios que ofrecen nuestras mallas para refuerzo de pisos de hormigón."},
    {id: 5, title: "Pasadores", slogan: 'Barras lisas para mínimo rozamiento',  link: "/productos#pasadores", img: "../../../assets/img/products/pasadores.jpg", img2: "../../../assets/img/products/pasadores2.jpg", img3: "../../../assets/img/products/pasadores3.jpg", img4: "../../../assets/img/products/pasadores4.jpg", description: "Una solución para unir bloques de piso de hormigón con los mismos beneficios que el resto de nuestra línea de productos."},
    {id: 6, title: "Estribos", slogan: 'Los hacemos a tu medida',  link: "/productos#estribos", img: "../../../assets/img/products/estribos3.jpg",  img2: "../../../assets/img/products/estribos.jpg", img3: "../../../assets/img/products/estribos2.jpg", img4: "../../../assets/img/products/estribos4.jpg", description: "Contamos con medidas estándar y la capacidad de adaptar nuestra producción de estribos a una gran variedad de formas geométricas."},
    {id: 7, title: "Armados", slogan: 'Personalizados completos para tu obra',  link: "/productos#armados", img: "../../../assets/img/products/armados.jpg", img2: "../../../assets/img/products/armados2.jpg", img3: "../../../assets/img/products/armados3.jpg", img4: "../../../assets/img/products/armados4.jpg", description: "Finalmente una solución competitiva para encadenados que integra nuestros estribos con las barras Barton 1000 para el refuerzo de vigas."},
];

export const listTag = [
    { id: 1, title: "Plateas y Veredas", link:'/aplicaciones?application=Plateas y Veredas', img:'./assets/img/soluciones/platea.jpg', gallery: [{url:'./assets/img/divisionBanner/divisionHome1.jpg'}, {url:'./assets/img/products/barra2.jpg'}, {url:'./assets/img/benefits/liviano/liviano800.jpg'}, {url:'./assets/img/benefits/resistente/resistente800.jpg'}, {url:'./assets/img/products/pasadores4.jpg'}, {url:'./assets/img/products/armados2.jpg'}, {url:'/assets/img/works/works1.jpg'}, {url:'./assets/img/works/works2.jpg'} ]},
    { id: 2, title: "Pisos Industriales", link:'/aplicaciones?application=Pisos Industriales', img:'./assets/img/soluciones/pisos.jpg', gallery: [{url:'./assets/img/divisionBanner/divisionHome1.jpg'}, {url:'./assets/img/products/barra2.jpg'}, {url:'./assets/img/benefits/liviano/liviano800.jpg'}, {url:'./assets/img/benefits/resistente/resistente800.jpg'}, {url:'./assets/img/products/pasadores4.jpg'}, {url:'./assets/img/products/armados2.jpg'}, {url:'/assets/img/works/works1.jpg'}, {url:'./assets/img/works/works2.jpg'} ]},
    { id: 3, title: "Piletas", link:"/aplicaciones?application=Piletas", img:'./assets/img/soluciones/piletas.jpg', gallery: [{url: './assets/img/exitos/ezeiza valentin/img1.jpg'}, {url: './assets/img/exitos/ezeiza valentin/img2.jpg'}, {url: './assets/img/exitos/ezeiza valentin/img3.jpg'}, {url: './assets/img/exitos/ezeiza valentin/img4.jpg'}, {url:'./assets/img/divisionBanner/divisionHome1.jpg'}, {url:'./assets/img/products/barra2.jpg'}, {url:'./assets/img/benefits/liviano/liviano800.jpg'}, {url:'./assets/img/benefits/resistente/resistente800.jpg'}]},
    { id: 4, title: "Premoldeados", link:'/aplicaciones?application=Premoldeados', img:'./assets/img/soluciones/premoldeados.jpg', imgPrincipal: './assets/img/soluciones/premoldeado/bannerPremoldeadox400.jpg', gallery: [{url: './assets/img/soluciones/premoldeado/Premoldeado2x600.jpg'}, {url: './assets/img/soluciones/premoldeado/Premoldeadox600.jpg'}, {url: './assets/img/soluciones/premoldeado/Premoldeado3x600.jpg'}, {url: './assets/img/exitos/merlo gonzalez/img1.jpg'}, {url:'./assets/img/exitos/merlo gonzalez/img2.jpg'}, {url:'./assets/img/exitos/merlo gonzalez/img3.jpg'}, {url:'./assets/img/exitos/merlo gonzalez/img4.jpg'}, {url:'./assets/img/exitos/merlo gonzalez/portada.jpg'}]},
    { id: 5, title: "Pilotes", link:'/aplicaciones?application=Pilotes', img:'./assets/img/soluciones/infraestructuras/infraestructuras.jpg', imgPrincipal: './assets/img/soluciones/infraestructuras/renderPilote.jpg', gallery: [{url: './assets/img/products/pilotes6.jpg'}, {url: './assets/img/products/pilotes2.jpg'}, {url: './assets/img/products/pilotes3.jpg'}, {url: './assets/img/products/pilotes4.jpg'}, {url: './assets/img/products/pilotes5.jpg'}, {url: './assets/img/products/pilotes1.jpg'}]},
    { id: 6, title: "Obras Eléctricas", img:'./assets/img/soluciones/electricas.jpg'}, //link:'/aplicaciones?application=Obras Eléctricas'
    { id: 7, title: "Construcciones ICF", img:'./assets/img/soluciones/icf.jpg'}, //, link:'/aplicaciones?application=Construcciones ICF'
    { id: 8, title: "Hidroeléctricas", img:'./assets/img/soluciones/hidroelectrica.jpg'}, //, link:'/aplicaciones?application=Hidroeléctricas'
    { id: 9, title: "Muelles y Puertos", img:'./assets/img/soluciones/muelles.jpg'}, //, link:'/aplicaciones?application=Muelles y Puertos'
    { id: 10, title: "Plantas Industriales", img:'./assets/img/soluciones/industrias.jpg'}, //, link:'/aplicaciones?application=Plantas Industriales'
];

export const listBenefits = [
    { id: 1, title:'4 VECES MÁS LIVIANO', logo:'./assets/img/benefits/liviano2.svg', img:'./assets/img/benefits/liviano/liviano800.jpg', link: '/beneficioLiviano', info:'Las barras de refuerzo son resistentes a la corrosión, a diferencia de las fabricadas con materiales tradicionales, que eventualmente se oxidaran y agrietarán el concreto.'},
    { id: 2, title:'3 VECES MÁS RESISTENTE', logo:'./assets/img/benefits/resistente2.svg', img:'./assets/img/benefits/resistente/resistente800.jpg', link: '/beneficioResistente', info:'Las barras de refuerzo son resistentes a la corrosión, a diferencia de las fabricadas con materiales tradicionales, que eventualmente se oxidaran y agrietarán el concreto.'},
    { id: 3, title:'FLEXIBLE', logo:'./assets/img/benefits/flexible.svg', img:'./assets/img/benefits/transportable/transportable800.jpg', link: '/beneficioTransportable', info:'Las barras de refuerzo son resistentes a la corrosión, a diferencia de las fabricadas con materiales tradicionales, que eventualmente se oxidaran y agrietarán el concreto.'},
    { id: 4, title:'NO CORROSIVO', logo:'./assets/img/benefits/corrosion.svg', img:'./assets/img/benefits/corrosion/anticorrosion800.jpg', link:'/beneficioAnticorrosion', info:'Las barras de refuerzo son resistentes a la corrosión, a diferencia de las fabricadas con materiales tradicionales, que eventualmente se oxidaran y agrietarán el concreto.'},
    { id: 5, title:'NO CONDUCTIVO', logo:'./assets/img/benefits/dielectrico.svg', img:'./assets/img/benefits/dielectrico/electrico800.jpg', link:'/beneficioAnticorrosion', info:'Las barras de refuerzo son resistentes a la corrosión, a diferencia de las fabricadas con materiales tradicionales, que eventualmente se oxidaran y agrietarán el concreto.'},
];

export const listTagSolution = [
    { id: 1, title: "Pisos Industriales", img:'./assets/img/soluciones/solucion.jpg', info:'Las barras de refuerzo son resistentes a la corrosión, a diferencia de las fabricadas con materiales tradicionales, que eventualmente se oxidaran y agrietarán el concreto.', atr1:'./assets/img/benefits/corrosion.png', atr2:'./assets/img/benefits/resistente.png', atr3:'./assets/img/benefits/flexible.png', atr4:'./assets/img/benefits/liviano.png', textatr1:'Primer beneficio: Anticorrosivo', textatr2:'Segundo beneficio: 3 veces más Resistente', textatr3:'Tercero beneficio: ahorre costo de Trasnporte', textatr4:'Cuarto beneficio: 7 veces más Liviano'},
    { id: 2, title: "Infraestructura", img:'./assets/img/soluciones/solucion3.jpg', info:'Las barras de refuerzo son resistentes a la corrosión, a diferencia de las fabricadas con materiales tradicionales, que eventualmente se oxidaran y agrietarán el concreto.', atr1:'./assets/img/benefits/corrosion.png', atr2:'./assets/img/benefits/resistente.png', atr3:'./assets/img/benefits/flexible.png', atr4:'./assets/img/benefits/liviano.png', textatr1:'Primer beneficio: Anticorrosivo', textatr2:'Segundo beneficio: 3 veces más Resistente', textatr3:'Tercero beneficio: ahorre costo de Trasnporte', textatr4:'Cuarto beneficio: 7 veces más Liviano'},
    { id: 3, title: "Obras Hidroelectricas", img:'./assets/img/soluciones/solucion4.jpg', info:'Las barras de refuerzo son resistentes a la corrosión, a diferencia de las fabricadas con materiales tradicionales, que eventualmente se oxidaran y agrietarán el concreto.', atr1:'./assets/img/benefits/corrosion.png', atr2:'./assets/img/benefits/resistente.png', atr3:'./assets/img/benefits/flexible.png', atr4:'./assets/img/benefits/liviano.png', textatr1:'Primer beneficio: Anticorrosivo', textatr2:'Segundo beneficio: 3 veces más Resistente', textatr3:'Tercero beneficio: ahorre costo de Trasnporte', textatr4:'Cuarto beneficio: 7 veces más Liviano'},
    { id: 4, title: "Premoldeados", img:'./assets/img/soluciones/solucion2.jpg', info:'Las barras de refuerzo son resistentes a la corrosión, a diferencia de las fabricadas con materiales tradicionales, que eventualmente se oxidaran y agrietarán el concreto.', atr1:'./assets/img/benefits/corrosion.png', atr2:'./assets/img/benefits/resistente.png', atr3:'./assets/img/benefits/flexible.png', atr4:'./assets/img/benefits/liviano.png', textatr1:'Primer beneficio: Anticorrosivo', textatr2:'Segundo beneficio: 3 veces más Resistente', textatr3:'Tercero beneficio: ahorre costo de Trasnporte', textatr4:'Cuarto beneficio: 7 veces más Liviano'},
];

export const sucessCases = [
    { 
        id: 1, 
        obra: "Piso llaneado", 
        type: "PISOS",
        img:"../../../assets/img/exitos/alvear dimoura/portada.jpg", 
        localidad: "Alvear, Rosario", 
        mt2: 3500,
        empresa: "Dimoura", 
        descripcion: "En un proyecto de gran envergadura, la empresa Dimoura confió en las barras de fibra de vidrio Barton® 1000 para garantizar un refuerzo óptimo en un galpón de 3500 m². Nuestras barras fueron esenciales en el armado de mallas que cubrieron toda la superficie, proporcionando una solución eficiente y duradera. Además, se utilizaron en barras de unión, pasadores y estacas, contribuyendo a la ejecución impecable de un piso llaneado de alta calidad, ideal para soportar las exigencias del proyecto. La elección de Barton® 1000 destacó por su resistencia a la corrosión y ligereza, características clave en construcciones de esta magnitud. Este caso no sólo refleja la versatilidad de nuestras barras, sino también la confianza de empresas como Dimoura en nuestros productos para proyectos que exigen máxima eficiencia y durabilidad.",
        position: { lat: -33.05830598144086, lng: -60.61709669089608 }, 
        gallery: [
            {url: '../../../assets/img/exitos/alvear dimoura/img1.jpg'},
            {url: '../../../assets/img/exitos/alvear dimoura/img2.jpg'},
            {url: '../../../assets/img/exitos/alvear dimoura/img3.jpg'},
            {url: '../../../assets/img/exitos/alvear dimoura/img4.jpg'},
        ]
         
    },
    { 
        id: 2,
        obra: "Piso Industrial", 
        type: "PISOS",
        img:"../../../assets/img/exitos/neuquen leiten/portada.jpg", 
        localidad: "Neuquén Capital",
        mt2: 1200,
        empresa: "Leiten", 
        descripcion: "La empresa Leiten, desde su sucursal en Neuquén, eligió las barras de fibra de vidrio Barton® 1000 para un proyecto clave: el armado de un piso de 1200 m². Nuestras barras se integraron en mallas de 25x25 cm, proporcionando un refuerzo uniforme y resistente que asegura la estabilidad y durabilidad del pavimento. Gracias a sus propiedades únicas, Barton® 1000 se adaptó perfectamente a las exigencias técnicas del proyecto, superando las expectativas de calidad. La decisión de utilizar Barton® 1000 en este trabajo no fue casualidad. Su ligereza, resistencia a la corrosión y facilidad de instalación hacen de nuestras barras la opción ideal para proyectos de gran precisión. Nos enorgullece formar parte de soluciones innovadoras que garantizan resultados sobresalientes en la construcción.", 
        position: { lat: -38.95297994522643, lng: -68.02413731534425 }, 
        gallery: [
            {url: '../../../assets/img/exitos/neuquen leiten/img1.jpg'},
            {url: '../../../assets/img/exitos/neuquen leiten/img2.jpg'},
            {url: '../../../assets/img/exitos/neuquen leiten/img3.jpg'},
            {url: '../../../assets/img/exitos/neuquen leiten/img4.jpg'},
        ]
    },
    { 
        id: 3,
        obra: "Piso Industrial",
        type: "PLANTAS INDUSTRIALES" ,
        img:"../../../assets/img/exitos/rodriguez leiten/portada.jpg",
        localidad: "General Rodríguez, Buenos Aires",
        mt2: 7000,
        empresa: "Leiten y Sinis",
        descripcion: "En un ambicioso proyecto de 7000 m² de piso llaneado, las empresas Leiten y Sinis unieron esfuerzos para garantizar un resultado de máxima calidad en sus sucursales ubicadas en el Polo Industrial General Rodríguez. Para este trabajo, eligieron las barras de fibra de vidrio Barton® 1000 como solución de refuerzo, integrándolas en mallas de 20x20 cm, pasadores, barras de unión y estacas. Gracias a su rendimiento superior, Barton® 1000 contribuyó a la durabilidad y estabilidad del pavimento, ideal para soportar las altas exigencias de este entorno industrial. La confianza depositada en Barton® 1000 por estas destacadas empresas reafirma nuestro compromiso con la excelencia. Nuestras barras, resistentes a la corrosión y fáciles de instalar, ofrecieron una solución eficiente y confiable para un proyecto de gran magnitud.",
        position: { lat: -34.63616254685721, lng: -58.9493378239616 },  
        gallery: [
            {url: '../../../assets/img/exitos/rodriguez leiten/img2.jpg'},
            {url: '../../../assets/img/exitos/rodriguez leiten/img3.jpg'},
            {url: '../../../assets/img/exitos/rodriguez leiten/img4.jpg'},
            {url: '../../../assets/img/exitos/rodriguez leiten/img1.jpg'},
        ] 
    },
    { 
        id: 4,
        obra: "Pileta",
        type: "PILETAS" ,
        img:"../../../assets/img/exitos/ezeiza valentin/portada.jpg",
        localidad: "Ezeiza, Buenos Aires",
        mt2: 40,
        empresa: "Piscinas Valentín",
        descripcion: "Para la construcción de una pileta de hormigón de 40 m² (10 m x 4 m), nuestras barras de fibra de vidrio Barton® 1000 fueron la elección ideal. Su resistencia a la corrosión y propiedades dieléctricas garantizaron un refuerzo confiable, protegiendo la estructura frente a los efectos del agua y el tiempo. Estas características hicieron de Barton® 1000 la solución perfecta para asegurar la durabilidad y seguridad de este proyecto. El uso de nuestras barras no sólo simplificó el proceso constructivo, sino que también ofreció ventajas a largo plazo en términos de mantenimiento y rendimiento. Este caso demuestra cómo Barton® 1000 puede marcar la diferencia en proyectos especializados como la construcción de piletas.",
        position: { lat: -34.78311278296248, lng:  -58.55349297754008 }, 
        gallery: [
            {url: '../../../assets/img/exitos/ezeiza valentin/img2.jpg'},
            {url: '../../../assets/img/exitos/ezeiza valentin/img3.jpg'},
            {url: '../../../assets/img/exitos/ezeiza valentin/img4.jpg'},
            {url: '../../../assets/img/exitos/ezeiza valentin/img1.jpg'},
        ]
    },

    { 
        id: 5,
        obra: "Premoldeados",
        type: "PREMOLDEADOS" ,
        img:"../../../assets/img/exitos/merlo gonzalez/portada.jpg",
        localidad: "Merlo, Buenos Aires",
        mt2: 3000,
        empresa: "González Rosa Leonarda 'El Ruso'",
        descripcion: "La empresa González Rosa Leonarda 'El Ruso', ubicada en Merlo, Buenos Aires, confió en las barras de fibra de vidrio Barton® 1000 para optimizar su producción de premoldeados. En este proyecto, se utilizaron 3000 metros lineales de barras Ø5, entregadas de forma quincenal, garantizando un suministro constante y eficiente para cubrir las necesidades del cliente. La elección de Barton® 1000 destacó por su durabilidad, resistencia a la corrosión y facilidad de manejo, características claves para proyectos de prefabricados de alta calidad. Este caso refleja cómo nuestras soluciones se adaptan a las demandas de la industria, ofreciendo resultados confiables y eficientes.",
        position: { lat: -34.67041448320496, lng:  -58.75487780402283}, 
        gallery: [
            {url: '../../../assets/img/exitos/merlo gonzalez/img1.jpg'},
            {url: '../../../assets/img/exitos/merlo gonzalez/img2.jpg'},
            {url: '../../../assets/img/exitos/merlo gonzalez/img3.jpg'},
            {url: '../../../assets/img/exitos/merlo gonzalez/img4.jpg'},
        ] 
    },
    { 
        id: 6,
        obra: " Vivienda unifamiliar",
        type: "PLATEAS" ,
        img:"../../../assets/img/exitos/escobar hemma/portada.jpg",
        localidad: "Belen de Escobar, BsAs",
        mt2: 365,
        empresa: "Hemma",
        descripcion: "Obra Barton Vivienda unifamiliar Hemma, Belen de Escobar Bs As.Barrio El Naudir Aguas Privadas. Vivienda de 365m2, se realizaron armadura de tabiques y columnas asi como platea y losas.",
        position: { lat: -34.31665029031889, lng: -58.7419585693131}, 
        gallery: [
            {url: '../../../assets/img/exitos/escobar hemma/img1.jpg'},
            {url: '../../../assets/img/exitos/escobar hemma/img2.jpg'},
            {url: '../../../assets/img/exitos/escobar hemma/img3.jpg'},
            {url: '../../../assets/img/exitos/escobar hemma/img4.jpg'},
        ] 
    },
    // { 
    //     id: 7,
    //     obra: "Estacion de servicio",
    //     type: "PLATEAS" ,
    //     img:"../../../assets/img/works/works4.jpg",
    //     localidad: "buenos Aires,BsAs",
    //     mt2: 1800,
    //     empresa: "shell",
    //     descripcion: descripcion,
    //     position: { lat: -32.8908,lng: -68.8272 },
    //     gallery: [
    //         {url: '../../../assets/img/works/works4.jpg'},
    //         {url: '../../../assets/img/works/works4.jpg'},
    //         {url: '../../../assets/img/works/works4.jpg'},
    //         {url: '../../../assets/img/works/works4.jpg'},
    //     ] 
    // },
    // {
    //     id: 8,
    //     obra: "Estacion de servicio",
    //     type: "PLATEAS" ,
    //     img:"../../../assets/img/works/works5.jpg",
    //     localidad: "la Plata,BsAs",
    //     mt2: 1000,
    //     empresa: "petronas",
    //     descripcion: descripcion,
    //     position: { lat: -34.9150054562126,lng: -58.378567695 },
    //     gallery: [
    //         {url: '../../../assets/img/works/works5.jpg'},
    //         {url: '../../../assets/img/works/works5.jpg'},
    //         {url: '../../../assets/img/works/works5.jpg'},
    //         {url: '../../../assets/img/works/works5.jpg'},
    //     ]
    // },
    // { 
    //     id: 9,
    //     obra: "Estacion de servicio",
    //     type: "PREMOLDEADOS" ,
    //     img:"../../../assets/img/works/works7.jpg",
    //     localidad: "buenos Aires,BsAs",
    //     mt2: 1500,
    //     empresa: "venezia",
    //     descripcion: descripcion,
    //     position: { lat: -32.9468,lng: -60.6393 },
    //     gallery: [
    //         {url: '../../../assets/img/works/works7.jpg'},
    //         {url: '../../../assets/img/works/works7.jpg'},
    //         {url: '../../../assets/img/works/works7.jpg'},
    //         {url: '../../../assets/img/works/works7.jpg'},
    //     ]
    // },
    // { 
    //     id: 10,
    //     obra: "Estacion de servicio",
    //     type: "PREMOLDEADOS" ,
    //     img:"../../../assets/img/works/works8.jpg",
    //     localidad: "la Plata,BsAs",
    //     mt2: 1200,
    //     empresa: "shell",
    //     descripcion: descripcion,
    //     position: { lat: -34.9150054562126,lng: -58.3787896512321 },
    //     gallery: [
    //         {url: '../../../assets/img/works/works8.jpg'},
    //         {url: '../../../assets/img/works/works8.jpg'},
    //         {url: '../../../assets/img/works/works8.jpg'},
    //         {url: '../../../assets/img/works/works8.jpg'},
    //     ]
    // },

    // { 
    //     id: 11,
    //     obra: "Estacion de servicio",
    //     type: "PLANTAS INDUSTRIALES" ,
    //     img:"../../../assets/img/works/works9.jpg",
    //     localidad: "buenos Aires,BsAs",
    //     mt2: 1800,
    //     empresa: "petronas",
    //     descripcion: descripcion,
    //     position: { lat: -34.4713,lng: -57.8441 },
    //     gallery: [
    //         {url: '../../../assets/img/works/works9.jpg'},
    //         {url: '../../../assets/img/works/works9.jpg'},
    //         {url: '../../../assets/img/works/works9.jpg'},
    //         {url: '../../../assets/img/works/works9.jpg'},
    //     ]
    // },
    // { 
    //     id: 12,
    //     obra: "Estacion de servicio",
    //     type: "PLANTAS INDUSTRIALES" ,
    //     img:"../../../assets/img/divisionBanner/divisionHome1.jpg",
    //     localidad: "la Plata,BsAs",
    //     mt2: 1000,
    //     empresa: "venezia",
    //     descripcion: descripcion,
    //     position: { lat: -34.9150054562126,lng: -58.7412368412365 },
    //     gallery: [
    //         {url: '../../../assets/img/divisionBanner/divisionHome1.jpg'},
    //         {url: '../../../assets/img/divisionBanner/divisionHome1.jpg'},
    //         {url: '../../../assets/img/divisionBanner/divisionHome1.jpg'},
    //         {url: '../../../assets/img/divisionBanner/divisionHome1.jpg'},
    //     ]
    // },

]
