import styled from "styled-components";
import CertificateImport from "./CertificateImport";

export default function GrupCertificateImport(props){
    return(
        <StyleGrupCertificateImport>
            <h2 className='containerTitle'>Certificados y Documentación Técnica</h2>
            <div className="containerGrupCertificate">
                {props.listCertificate.map((data) => (
                  <CertificateImport key={data.id} listCertificate={data} />
                ))}
            </div>
        </StyleGrupCertificateImport>
    )
}

const StyleGrupCertificateImport = styled.div `
text-align: center;
    padding-top: 5.5rem;
    @media (min-width: 768px){
        padding: 7.5rem 0 rem 7.5rem 0rem;
    }

    .containerGrupCertificate{
        @media (min-width: 768px) {
            display: flex;
            justify-content: space-around;
        }
    }

`