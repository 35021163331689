import styled from "styled-components";
import Calculating from "../../../component/calculating/Calculating";
import { useSearchParams } from 'react-router-dom';
import Barton1000Price from "../../../component/prices/Barton1000Price";
import CalculatingPool from "../../../component/calculating/CalculatingPool";
import CalculatingPiles from "../../../component/calculating/CalculatingPiles";
import { listTag } from "../../../mocks/mock"
import MallaPrice from "../../../component/prices/MallaPrice";
// import TableBarton from "../../../component/tables/TableBarton";
// import Prices from "../../../component/prices/Prices";
// import Documentation from "../../../component/documentation/Documentation";

export default function Application() { 
    const [searchParams] = useSearchParams();

    const application = searchParams.get('application');
    
    const result = listTag.find(item => item.title === application);
 
    return (
        <>
            <StyleApplication>
                <div className="container-fluid" style={{width: '90%', marginTop: "10rem" }}>
                    <h2>VENTAJAS DE BARTON EN {application.toLocaleUpperCase()}</h2>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="benefit">
                                <i className="fa fa-check" aria-hidden="true"></i>
                                {application === 'Pilotes' ? <p className="bigText">Desarrollo de fisuras más controlado</p> : <p className="bigText">Menos fisuras por contracción</p>}
                            </div>
                            <div className="benefit">
                                <i className="fa fa-check" aria-hidden="true"></i>
                                {application === 'Pilotes' ? <p className="bigText">Fácil manipulación gracias a su bajo peso</p> : <p className="bigText">Menos deterioro, en todos los climas</p>}
                            </div>
                            <div className="benefit">
                                <i className="fa fa-check" aria-hidden="true"></i>
                                {application === 'Pilotes' ? <p className="bigText">Gran ahorro en materiales y mano de obra</p> : <p className="bigText">Menos fisuras, por asentamiento plástico</p>}
                            </div>
                            <div className="benefit">
                                <i className="fa fa-check" aria-hidden="true"></i>
                                {application === 'Pilotes' ? <p className="bigText">No más oxido en las estructuras</p> : <p className="bigText">Menos roturas y hundimientos, por base deficiente</p>}
                            </div>
                            <div className="benefit" style={{ marginTop: "10%" }}>
                                <div className="benefitImg">
                                    <img src="./assets/img/benefits/corrosion.svg" alt="Resistencia a la corrosión" />
                                </div>
                                <p className="tinyText">
                                    <strong>Resistencia a la corrosión</strong>: No se corroen ni se oxidan, lo que garantiza una mayor durabilidad y vida útil del piso.
                                </p>
                            </div>
                            <div className="benefit">
                                <div className="benefitImg">
                                    <img src="./assets/img/benefits/resistente2.svg" alt="Durabilidad" />
                                </div>
                                <p className="tinyText">
                                    <strong>Mayor durabilidad</strong>: Las barras de fibra de vidrio son extremadamente duraderas y pueden soportar cargas pesadas y tráfico intenso, sin deformarse ni romperse.
                                </p>
                            </div>
                            <div className="benefit">
                                <div className="benefitImg">
                                    <img src="./assets/img/benefits/dielectrico.svg" alt="Dieléctrico" />
                                </div>
                                <p className="tinyText">
                                    <strong>Dieléctrico</strong>: La fibra de vidrio de los productos Barton, tienen una baja conductividad térmica y eléctrica.
                                </p>
                            </div>
                            <div className="benefit">
                                <div className="benefitImg">
                                    <img src="./assets/img/benefits/liviano2.svg" alt="Liviano" />
                                </div>
                                <p className="tinyText">
                                    <strong>Liviano</strong>: Todos los productos Barton son significativamente más ligeros que el hierro de construcción, lo que facilita su manipulación, transporte y aplicación.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6" style={{marginBottom: '4rem'}}>
                            {result.imgPrincipal ? (
                                <>
                                    <div className="containerImgPrincipal">
                                        <img src={result.imgPrincipal} alt="Imagen principal" />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="videoContainer">
                                        <iframe
                                            title="Video Home"
                                            src={
                                                "https://player.vimeo.com/video/829485591?controls=0&autopause=false&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1"
                                            }
                                            frameBorder="0"
                                            allow="autoplay; fullscreen; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                </>
                            )}
                            <div className="gallery">
                                {result.gallery ? (
                                    result.gallery.map((img, id) => (
                                        <div key={id}>
                                            <img src={img.url} alt={`Imagen galería ${id}`} data-bs-toggle="modal" data-bs-target={`#modal-${id}`} />
                                            <div className="modal fade" id={`modal-${id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-xl">
                                                    <div className="modal-content">
                                                    <div className="modal-header">
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <img src={img.url} alt={`Imagen galería ${id}`} />
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))) : null } 
                            </div>
                        </div>
                    </div>
                </div>
                    {(application === "Pisos Industriales" || application === "Plateas y Veredas") ? <Calculating /> : null}
                    {(application === "Piletas" ) ? <CalculatingPool /> : null}
                    {(application === "Pilotes") ? <CalculatingPiles/> : null}
                    <Barton1000Price application={application} />
                    {(application === "Plateas y Veredas" || application === "Premoldeados" || application === "Construcciones ICF") ? <MallaPrice application={application} /> : null}
            </StyleApplication>
        </>
    );
}

const StyleApplication = styled.div`
    h2 {
        color: var(--colorTerciario);
        font-size: 2.2rem;
        margin: 2rem 0rem;
        @media (min-width: 768px) {
            margin: 4rem 0rem;
            text-align: center;
            font-size: 2.8rem;
        }
    }

    .benefit {
        display: grid;
        grid-template-columns: 10% 90%; /* Cambio para un layout más adecuado */
        gap: 1rem; /* Espacio entre la imagen y el texto */
        align-items: center;
        margin-bottom: 1rem;
        @media (min-width: 768px) {
            width: 90%;
        }
        i {
            width: 1rem;
            text-align: center;
            color: green;
            margin-right: 0.5rem;
            font-size: 3rem;
        }

        .bigText {
            margin: 0;
            font-weight: bold;
            @media (min-width: 768px) {
                font-size: 1.5rem;
            }
        }

        .tinyText {
            color: #3e6654;
        }
    }
    .containerImgPrincipal {
        width: 100%;
        height: 450px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .benefitImg {
        width: 100%;
        text-align: center;
        margin-bottom: 1rem; /* Espacio debajo de la imagen */
    }

    .videoContainer {
        position: relative;
        width: 100%;
        padding-top: 42%; /* Relación de aspecto 16:9 */
        height: 0 !important;
        overflow: hidden;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    .gallery {
        display: grid;
        gap: 1rem; /* Espacio entre imágenes */
        margin-top: 1rem;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 150px; /* Fija la altura de las celdas */
        width: 100%;
        overflow: hidden;

        @media (min-width: 576px) {
            grid-template-columns: repeat(3, 1fr);
            grid-auto-rows: 140px; /* Mantiene la altura fija */
        }

        @media (min-width: 768px) {
            grid-template-columns: repeat(4, 1fr);
        }

        @media (min-width: 992px) {
            grid-template-columns: repeat(2, 1fr);
        }
        
        @media (min-width: 1200px) {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    .gallery img {
        width: 100%;
        height: 100%; /* Asegúrate de que las imágenes llenen la celda */
        object-fit: cover; /* Ajusta las imágenes al tamaño de la celda sin distorsionarlas */
    }

`;

