import styled from "styled-components";
import CalculatingPool from "../../../component/calculating/CalculatingPool";
import { useSearchParams } from 'react-router-dom';
import Barton1000Price from "../../../component/prices/Barton1000Price";
// import TableBarton from "../../../component/tables/TableBarton";
// import Prices from "../../../component/prices/Prices";
// import Documentation from "../../../component/documentation/Documentation";

export default function ApplicationPlantasIndustriales() { 
    // const [searchParams] = useSearchParams();

    // const application = searchParams.get('application');
    return (
        <>
            <StyleApplication>
                <div className="container-fluid" style={{ width: "95%", marginTop: "10rem" }}>
                    <h2>VENTAJAS DE BARTON EN PLANTAS INDUSTRIALES</h2>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="benefit">
                                <i className="fa fa-check" aria-hidden="true"></i>
                                <p className="bigText">Menos fisuras por contracción</p>
                            </div>
                            <div className="benefit">
                                <i className="fa fa-check" aria-hidden="true"></i>
                                <p className="bigText">Menos deterioro, en todos los climas</p>
                            </div>
                            <div className="benefit">
                                <i className="fa fa-check" aria-hidden="true"></i>
                                <p className="bigText">Menos fisuras, por asentamiento plástico</p>
                            </div>
                            <div className="benefit">
                                <i className="fa fa-check" aria-hidden="true"></i>
                                <p className="bigText">Menos roturas y hundimientos, por base deficiente</p>
                            </div>
                            <div className="benefit" style={{ marginTop: "10%" }}>
                                <div className="benefitImg">
                                    <img src="./assets/img/benefits/corrosion.svg" alt="Resistencia a la corrosión" />
                                </div>
                                <p className="tinyText">
                                    <strong>Resistencia a la corrosión</strong>: No se corroen ni se oxidan, lo que garantiza una mayor durabilidad y vida útil del piso
                                </p>
                            </div>
                            <div className="benefit">
                                <div className="benefitImg">
                                    <img src="./assets/img/benefits/liviano2.svg" alt="Liviano" />
                                </div>
                                <p className="tinyText">
                                    <strong>Liviano</strong>: Todos los productos Barton son significativamente más ligeros que el hierro de construcción, lo que facilita su manipulación, transporte y aplicación
                                </p>
                            </div>
                            <div className="benefit">
                                <div className="benefitImg">
                                    <img src="./assets/img/benefits/dielectrico.svg" alt="Dieléctrico" />
                                </div>
                                <p className="tinyText">
                                    <strong>Dieléctrico</strong>: La fibra de vidrio de los productos Barton, tienen una baja conductividad térmica y eléctrica.
                                </p>
                            </div>
                            <div className="benefit">
                                <div className="benefitImg">
                                    <img src="./assets/img/benefits/resistente2.svg" alt="Durabilidad" />
                                </div>
                                <p className="tinyText">
                                    <strong>Mayor durabilidad</strong>: Las barras de fibra de vidrio son extremadamente duraderas y pueden soportar cargas pesadas y tráfico intenso, sin deformarse ni romperse
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="videoContainer">
                                <iframe
                                    title="Video Home"
                                    src={
                                        "https://player.vimeo.com/video/829485591?controls=0&autopause=false&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1"
                                    }
                                    frameBorder="0"
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                            <div className="galery">
                                <img src="../../../assets/img/divisionBanner/divisionHome1.jpg" alt="imagenChiquita1" />
                                <img src="../../../assets/img/products/barra2.jpg" alt="imagenChiquita2" />
                                <img src="../../../assets/img/benefits/liviano/liviano800.jpg" alt="imagenChiquita3" />
                                <img src="../../../assets/img/benefits/resistente/resistente800.jpg" alt="imagenChiquita4" />
                                <img src="../../../assets/img/products/pasadores4.jpg" alt="imagenChiquita5" />
                                <img src="../../../assets/img/products/armados2.jpg" alt="imagenChiquita6" />
                                <img src="../../../assets/img/works/works1.jpg" alt="imagenChiquita7" />
                                <img src="../../../assets/img/works/works2.jpg" alt="imagenChiquita8" />
                            </div>

                        </div>
                    </div>
                    <Barton1000Price application={"plantas industriales"} />
                    <CalculatingPool />
                </div>
            </StyleApplication>
        </>
    );
}

const StyleApplication = styled.div`
    h2 {
        color: var(--colorTerciario);
        font-size: 2.2rem;
        margin: 2rem 0rem;
        @media (min-width: 768px) {
            margin: 4rem 0rem;
            text-align: center;
            font-size: 2.8rem;
        }
    }

    .benefit {
        display: grid;
        grid-template-columns: 10% 90%; /* Cambio para un layout más adecuado */
        gap: 1rem; /* Espacio entre la imagen y el texto */
        align-items: center;
        margin-bottom: 1rem;

        i {
            width: 1rem;
            text-align: center;
            color: green;
            margin-right: 0.5rem;
            font-size: 3rem;
        }

        .bigText {
            margin: 0;
            font-weight: bold;
            @media (min-width: 768px) {
                font-size: 1.5rem;
            }
        }

        .tinyText {
            color: #3e6654;
        }
    }

    .benefitImg {
        width: 100%;
        text-align: center;
        margin-bottom: 1rem; /* Espacio debajo de la imagen */
    }

    .videoContainer {
        position: relative;
        width: 100%;
        padding-top: 42%; /* Relación de aspecto 16:9 */
        height: 0 !important;
        overflow: hidden;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    .galery {
        display: grid;
        gap: 1rem; /* Espacio entre imágenes */
        margin-top: 1rem;
        grid-template-columns: repeat(2, 1fr);
        
        @media (min-width: 576px) {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, auto);    
        }

        @media (min-width: 768px) {
            grid-template-columns: repeat(4, 1fr);
        }

        @media (min-width: 992px) {
            grid-template-columns: repeat(2, 1fr);
        }
        
        @media (min-width: 1200px) {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    .galery img {
        width: 100%;
        height: 200px;
    }
`;

