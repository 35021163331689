import GrupCertificateImport from "../CertificateImport/GrupCertificateImport";
import AccordionsGroup from "../accordions/AccordionsGroup";

import Comparison from "../comparison/Comparison";

export default function Documentation() {
    return (
        <section style={{backgroundColor: 'var(--colorFondoMain)', paddingBottom:"5rem"}} id='documentacion'>
            <div className="container-fluid">
                <GrupCertificateImport listCertificate={listCertificate}/>
                <div className="row" style={{margin: '4rem 0rem'}}>
                    <div className="col-12 col-xl-5" style={{display: 'flex', alignItems: 'flex-start'}}>
                        <AccordionsGroup sampleList={listPDF}/>
                    </div>
                    <div className="col-12 col-xl-7">
                        <Comparison comparisonInfo={listComparison}/>
                    </div>
                </div>
            </div>
        </section>
    )
};

const listCertificate =[
    { id: 1, title: "Certificado de Infome de Ensayo de INTI", info:'Certificado OT N° 226-4361', donwload:"/assets/pdf/normas/CertificadoInformeEnsayo.pdf", img:'./assets/pdf/normas/CertificadoInformeEnsayo.png', with:'PDF | 572 KB'},
    { id: 2, title: "Certificado de Calibración / Medición de INTI", info:'Certificado OT N° 222-5888', donwload:"/assets/pdf/normas/CertificadoCalibracionMedicion.pdf", img:'./assets/pdf/normas/CertificadoCalibracionMedicion.png', with:'PDF | 414 KB'},
]

const listPDF = [
    { id: 1, title: "Norma ACI 440 3R", logo: '/assets/img/normas/aci.jpeg', info:'Norma ACI 440 3R', donwload:"/assets/pdf/normas/Norma-ACI-440-3R.pdf"},
    { id: 2, title: "Norma ASTM D7957", logo: '/assets/img/normas/astm.jpeg', info:'Norma ASTM D7957', donwload:"/assets/pdf/normas/Norma-ASTM D7957.pdf"},
    { id: 3, title: "Norma ISO 10406-1 2008", logo:'/assets/img/normas/iso.jpeg', info:'Norma ISO 10406-1 2008', donwload:"/assets/pdf/normas/Norma-ISO-10406-1-2008.pdf"},
    { id: 4, title: "¿Por qué Barton 1000?", info:'Beneficios de Barton 1000', donwload:"/assets/pdf/normas/Por que Barton.pdf"},
    { id: 5, title: "Ficha Técnica", info:'Barras de Refuerzo en Fibra de Vidrio - Ficha de Producto 2024', donwload:"/assets/pdf/normas/Ficha Tecnica.pdf"},
    { id: 6, title: "Preguntas Frecuentes Barton 1000", info:'Preguntas Frecuentes', donwload:"/assets/pdf/normas/Preguntas frecuentes BARTON.pdf"},
    { id: 7, title: "Instrucciones de Uso Seguro (MSDS)", info:'Como Manipular de Manera Segura las Barras Barton 1000', donwload: "/assets/pdf/normas/Instrucciones de uso seguro.pdf"},
    { id: 8, title: "Ensayo de Fuego", info:'Ensayo de Fuego', donwload:"/assets/pdf/normas/ensayoFuego.pdf"},
];

const listComparison = {
    title: 'Prueba de Tracción', 
    titleVideo: 'Prueba de tracción',
    video: 'https://player.vimeo.com/video/821118537?controls=0?&autopause=false&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1',
    info: 'Las barras Barton 1000, son testeadas en nuestro laboratorio con equipamiento certificado por INTI, numero de certificado: IF-2022-118714672-APN-SOMCEI*INTI',
};