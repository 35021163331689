//funcion para aplicar . cada mil y , decimal
const toThousand = n => {
    const parts = n.toString().split('.');
    const integerPart = parts[0];
    const decimalPart = parts[2] || '';
    
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    const result = decimalPart ? `${formattedInteger},${decimalPart}` : formattedInteger;
    
    return result;
};

export default toThousand