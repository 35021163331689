import { Link } from "react-router-dom";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import styled from "styled-components";

export default function Tag (props){
  const [windowWidth] = useWindowWidth();
  let img = props.tagInfo.img;

  // Función para determinar la clase de ancho
  function getColWidth(windowWidth) {
      if (windowWidth < 992) {
          return 'col-sm-6 col-md-6';
      } else {
          return 'col-xl-5';
      }
  }
    
  return (
    <>
      <div className={getColWidth(windowWidth)} style={{ width: windowWidth <992 ? '50%' : windowWidth >= 992 && windowWidth <1200 ? '25%' : '20%', padding: '.1rem'}}>
        <StyledTag>
          <div className="card">
            <Link to={props.tagInfo.link}>
              <div className="card-img">
                <img src={img} alt={img} className="card-img-top"/>
              </div>
              <div className="containerInfo">
                    <h3>{props.tagInfo.title}</h3>
              </div>
            </Link>
          </div>
        </StyledTag>
      </div>
    </>
  );
}

const StyledTag = styled.div`
    margin-bottom: 1.5rem;
    .card-img {
      overflow: hidden; 
      height: 240px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      @media (min-width: 576px) {
        height: 500px;
      }
    }
    .card-img-top {
      min-height: 100%;
      object-fit: cover;
      }
      img {
        transition: 0.2s;
      }
      img:hover {
        transition: 0.1s;
        transform: scale(1.05);
    }
    .containerInfo{
        text-align: center;
        padding: 0.5rem;
        min-height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (min-width: 768px) {
          padding: 1rem;
        }
        h3 {
          color: var(--colorTerciario);
          margin-bottom: 0rem;
          font-size: 1rem;
          @media (min-width: 768px) {
            font-size: 1.5rem;
          }
        }
    }


`