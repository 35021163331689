import styled from "styled-components";

export default function CertificateImport (props){
    return(
        <StyleCertificateImport>
                <a href={props.listCertificate.donwload} target="_blank" rel="noreferrer noopener">
            <div className="containerCertificateImport">
                <div>
                    <img className="imgCertificate" src={props.listCertificate.img} alt="Documento PDF" />
                </div>
                <div>
                    <h5 className="titleCertificate">{props.listCertificate.title}</h5>
                    <p>{props.listCertificate.info}</p>
                    <p><img className="imgPDF" src="./assets/img/pdf.png" alt="logoPDF"/>{props.listCertificate.with}</p>
                </div>
            </div>
                </a>
        </StyleCertificateImport>
    )
}

const StyleCertificateImport = styled.div`
    .containerCertificateImport{
        display: flex;
        padding-top: 2rem;
        flex-direction: column-reverse;
        align-items: center;
        @media (min-width: 768px){
            flex-direction: row;
            gap: 2rem;
        }
    }
    .titleCertificate{
        color: var(--colorTerciario);
    }

    .imgCertificate{
        width: 300px;
        @media (min-width: 768px){
            width: 150px;
        }
    }
    .imgPDF{
        width: 25px;
    }
`