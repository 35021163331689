import styled from "styled-components";

export default function DivisionImg(props) {
    return (
        <StyleDivisionImg imageUrl={props.imageUrl}>
            <div className="containerImg">
                {props.showOverlay && <div className="overlay"></div>}
                {props.title && 
                    <h2 className="titleDivision">{props.title}</h2>
                }
            </div>
        </StyleDivisionImg>
    );
}

const StyleDivisionImg = styled.div`
    margin: 4rem 0rem;
    @media (min-width: 768px) {
        margin: 3rem 0rem;
    }
    .containerImg {
        background-image: url(${props => props.imageUrl});
        background-repeat: no-repeat;
        background-position: center;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative; /* Necesario para posicionar la capa negra */
        overflow: hidden; /* Evita que elementos absolutos se salgan del contenedor */

        @media (min-width: 768px) {
            //height: 350px;
        }
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.3; /* Controla la transparencia de la capa negra */
        z-index: 1; /* Asegura que esté detrás del texto */
    }
    .titleDivision {
        color: white;
        position: relative; /* Asegura que el texto esté encima de la capa */
        z-index: 2;
    }
`;

