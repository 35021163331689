import styled from "styled-components";

export default function Normas(){
    return(
        <StyleNormas>
            <div className="containerLogo">
                <img src="./assets/img/normas/logo-inti.png" alt="Logo Inti" />
                <img src="./assets/img/normas/logo-iso.png" alt="Logo Iso9001" />
            </div>
        </StyleNormas>
    )
}

const StyleNormas = styled.div`
    .containerLogo{
        display: flex;
        justify-content: center;
        padding: var(--paddinLateralMobile);
        width: 100%;
        margin-top: 3.5rem;
        @media (min-width: 768px){
            padding: var(--paddinLateral);
        }
    }
    img{
        width: 100px;
    }
`