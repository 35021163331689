import TagComplet from './TagComplet';
import styled from 'styled-components';

export default function TagGrups(props){
    return(
        <StyleTagGrups>
            <div className='containerTagsGroup'>
                    {props.listTag.map((data) => (
                        <TagComplet key={data.id} tagInfo={data}/>
                    ))}
            </div>
        </StyleTagGrups>
    )
}

const StyleTagGrups = styled.div`
    .containerTagsGroup{
        margin: 3.5rem 0rem;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        /* padding: var(--paddinLateralMovile); */

        @media(min-width: 768px){
            /* padding: var(--paddinLateral); */
        }
            /* @media(min-width: 992px){
            display: flex;
            justify-content: space-between;
            width: auto;
        } */
    }
`