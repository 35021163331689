export default function ContactView() {
    return (
        <section>
            <div>Contacto</div>
            <div>Contacto</div>
            <div>Contacto</div>
            <div>Contacto</div>
            <div>Contacto</div>
            <div>Contacto</div>
            <div>Contacto</div>
        </section>
    )
}