import styled from "styled-components"
import ProductDescription from "../../component/products/ProductDescription"
import { useState } from "react"
import { HashLink } from 'react-router-hash-link';
import DivisionImg from "../../component/divisionImg/DivisionImg.jsx";

//mocks 
import { listProducts } from "../../mocks/mock"
import PasadoresPrices from "../../component/prices/PasadoresPrice.jsx";
import Barton1000Price from "../../component/prices/Barton1000Price.jsx";
import MallaPrice from "../../component/prices/MallaPrice.jsx";

export default function ProductsView() {
    const [barton1000] = useState(listProducts[0]);
    const [mallas] = useState(listProducts[3]);
    const [pasadores] = useState(listProducts[4]);
    const [estribos] = useState(listProducts[5]);
    const [armados] = useState(listProducts[6]);


    return (
        <StyleProductsView>
            <div className="container-fluid">
                <div className="row">
                    <div className="colo-12 col-lg-6">
                        <div className="containerProduct">
                            <h1>PRESENTACIONES BARTON</h1>
                        </div>
                    </div>
                    <div className="colo-12 col-lg-6">
                        <div className="containerModels">
                            {listProducts.map((product, index) => (
                                index !== 1 && index !== 2 && (
                                    <HashLink className="linkActivo" key={index} to={product.link}>
                                        <h5 className="textLinkActivo">{product.title}</h5>
                                        <i className="fa-solid fa-caret-down"></i>
                                    </HashLink>
                                )
                            ))}
                        </div>
                    </div>
                </div>
                    
                <div style={{marginTop: '1rem'}}>
                    <ProductDescription
                        slogan={barton1000.slogan}
                        description={barton1000.description}
                        mainImageSrc={barton1000.img}
                        sliderImages={[
                            barton1000.img2,
                            barton1000.img4,
                            barton1000.img3
                        ]}
                     />
                </div>
            </div>
    
            <Barton1000Price/>

            <div id="mallas" style={{marginBottom: '5rem'}}></div>
            <DivisionImg showOverlay={true} title={"MALLAS"} imageUrl="assets/img/products/mallas2.jpg"/>

            <div   className="container-fluid">
                <ProductDescription
                    slogan={mallas.slogan}
                    description={mallas.description}
                    mainImageSrc={mallas.img}
                    sliderImages={[
                        mallas.img2,
                        mallas.img3,
                        mallas.img4
                    ]}
                />
            </div>

            <MallaPrice application={'Premoldeados'}/>
            
            <div id="pasadores"  style={{marginBottom: '5rem'}}></div>
            <DivisionImg showOverlay={true} title={"PASADORES"} imageUrl="assets/img/divisionBanner/divisionHome1.jpg"/>
            
            <div className="container-fluid">
                <ProductDescription
                    slogan={pasadores.slogan}
                    description={pasadores.description}
                    mainImageSrc={pasadores.img}
                    sliderImages={[
                        pasadores.img2,
                        pasadores.img3,
                        pasadores.img4
                    ]}
                    />
            </div>

            <PasadoresPrices />
            <div id="estribos" style={{marginBottom: '5rem'}}></div>
            <DivisionImg showOverlay={true} title={"ESTRIBOS"} imageUrl="assets/img/products/estribos2.jpg"/>

            <div className="container-fluid">
                <ProductDescription
                    slogan={estribos.slogan}
                    description={estribos.description}
                    mainImageSrc={estribos.img2}
                    sliderImages={[
                        estribos.img,
                        estribos.img3,
                        estribos.img4
                    ]}
                    />
            </div>

            <div id="armados" style={{marginBottom: '11rem'}}></div>
            <DivisionImg showOverlay={true} title={"ARMADOS"} imageUrl="assets/img/divisionBanner/divisionHome1.jpg"/>

            <div className="container-fluid">
                <ProductDescription
                    slogan={armados.slogan}
                    description={armados.description}
                    mainImageSrc={armados.img}
                    sliderImages={[
                        armados.img2,
                        armados.img3,
                        armados.img4
                    ]}
                    />
            </div>     
        </StyleProductsView>
    )
}

const StyleProductsView = styled.div`
    margin-top: var(--alturaNavBar);
    .containerProduct {
        margin-top: 3rem;
    }
    .containerModels {
        display: grid;
        grid-template-columns: 50% 50%;
        @media (min-width: 768px) {
            margin-top: 3rem;
            display: flex;
            justify-content: space-around;
        }
    }
    .linkActivo {
        color: var(--colorTerciario);
        text-align: center;
        }
        
    .textLinkActivo {
        border-bottom: 2px solid;
        color: var(--colorTerciario);
        width: 90%;
        margin: 0 auto;
        @media (min-width: 768px) {
            width: 100%;
        }
    }
    .linkNoActivo {
        color: #000;
        text-align: center;
        }
        
    .textLinkNoActivo {
        border-bottom: 2px solid;
        color: #000;
    }
`   