import Benefit from "./Benefit";
import styled from "styled-components";

export default function BenefitsGrup(props){

    return(
        <StyledBenefitGrup className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <h2 className="containerTitle">BARTON EN TU PRÓXIMA OBRA</h2>
                </div>
                <BenefitsContainer>
                    {props.listBenefits.map((data) => (
                        <Benefit key={data.id} benefitInfo={data}/>
                    ))}
                </BenefitsContainer>
            </div>
        </StyledBenefitGrup>
    )
}

const StyledBenefitGrup = styled.div`
    
    .containerTitle {
        text-align: center;
        margin: 3rem 0 1rem 0rem;
        @media (min-width: 768px){
            margin-top: 6rem;
        }
    }
`
const BenefitsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);;
    @media (min-width: 768px){
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
`