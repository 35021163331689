const CustomerService = async () => {
        try {
            const response = await fetch('https://admin.leiten.com.ar/api/getCurrencyNames'); //Reemplaza 'URL_DE_TU_API' con la URL de tu API
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return (data[0].value);
        } catch (error) {
            console.error('Error fetching currency:', error);
            return null;
        }
    }

export default CustomerService