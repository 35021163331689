import styled from "styled-components";

export default function Tag (props){
    let img = props.tagInfo.img;

    return(
        <StyledTag>
            <div className="containerTag">
                <div className="cantainerImg">
                    <img src={img} alt={img}></img>
                </div>
                <div className="containerInfo">
                    <h4>{props.tagInfo.title}</h4>
                    <p>{props.tagInfo.info}</p>
                    <div className="containerAttributes">
                        <div className="containerAttributesIndv">
                            <img src={props.tagInfo.atr1} alt="atributo 1" /><p>{props.tagInfo.textatr1}</p>
                        </div>
                        <div className="containerAttributesIndv">
                            <img src={props.tagInfo.atr2} alt="atributo 2" /><p>{props.tagInfo.textatr2}</p>
                        </div>
                        <div className="containerAttributesIndv">
                            <img src={props.tagInfo.atr3} alt="atributo 3" /><p>{props.tagInfo.textatr3}</p>
                        </div>
                        <div className="containerAttributesIndv">
                            <img src={props.tagInfo.atr4} alt="atributo 4" /><p>{props.tagInfo.textatr4}</p>
                        </div>
                    </div>
                </div>
            </div>
        </StyledTag>

    )
}

const StyledTag = styled.div`
    .containerTag{
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 10px 0;
        border: var(--colorFondoMain) solid 2px;
        border-radius: 0px 0px 10px 10px;
        line-height: 2;
        text-align: justify;
        justify-items: center;
        
        @media(min-width: 768px){
            flex-direction: row;
            margin: 10px;
            justify-content: space-between;
            margin-top: 3.5rem;
            display: grid;
            grid-template-columns: repeat(2, 0.5fr);
        }
    }
    .containerInfo{
        margin: 10px 0px;
        padding: 1rem;
    }
    .cantainerImg {
        width: 90%;
    }
    .cantainerImg img{
        min-width: 90%;
        padding: 1rem;
    }
    .containerAttributes{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
        align-content: center;
        @media(min-width: 768px){
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            margin-top: 2rem;
        }
        @media(min-width: 1500px){
            display: grid;
            justify-items: stretch;
        }
    .containerAttributesIndv{
        @media (min-width: 768px){
            display: flex;
            align-items: center;
            margin-top: 2rem;
        }
    }
    }
`