import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useWindowWidth } from '../../hooks/useWindowWidth.jsx';
import { sucessCases } from "../../mocks/mock.js";
import ImageWithTextButton from '../../component/specialImages/ImageWithButton.jsx';
import { APIProvider, Map, AdvancedMarker, Pin } from "@vis.gl/react-google-maps";

function CheckBox({ children, onChange }) {    
    return (
        <CheckBoxWrapper>
            <span>{children}</span>
            <input onChange={(e) => { onChange(e, children) }} type="checkbox" />
        </CheckBoxWrapper>
    );
}

export default function SuccessCasesView() {
    const [windowWidth] = useWindowWidth();
    const [filteredCases, setFilteredCases] = useState(sucessCases);
    const [firstClick, setFirstClick] = useState(true);

    const defaultZoom = windowWidth < 768 ? 4 : 5;

    const handleChange = (e, name) => {
        if (firstClick) {
            setFilteredCases([]);
            setFirstClick(false);
        }

        if (e.target.checked) {
            setFilteredCases((prevCases) => [
                ...prevCases,
                ...sucessCases.filter((caseData) => caseData.type.toLowerCase() === name.toLowerCase())
            ]);
        } else {
            setFilteredCases((prevCases) =>
                prevCases.filter((caseData) => caseData.type.toLowerCase() !== name.toLowerCase())
            );
        }
    };

    useEffect(() => {
        if (filteredCases.length === 0) {
            setFilteredCases(sucessCases);
            setFirstClick(true);
        }
    }, [filteredCases]);

    return (
        <>
            <StyleSuccessCasesView>
                <div className="container-fluid">
                    <div className="row" style={{ textAlign: 'center' }}>
                        <h1 className='title'>Casos de Éxito con Barton</h1>
                        <div className="containerCheckbox">
                            <CheckBox onChange={handleChange}>PISOS</CheckBox>
                            <CheckBox onChange={handleChange}>PILETAS</CheckBox>
                            {/* <CheckBox onChange={handleChange}>PILOTES</CheckBox> */}
                            <CheckBox onChange={handleChange}>PLATEAS</CheckBox>
                            <CheckBox onChange={handleChange}>PREMOLDEADOS</CheckBox>
                            <CheckBox onChange={handleChange}>PLANTAS INDUSTRIALES</CheckBox>
                        </div>
                    </div>
                    
                    <div className="row" style={{ margin: '1rem 0rem' }}>
                        <div className="col-lg-6">
                            <div className="containerCases" style={{width: '95%', margin: '0 auto'}}>
                                <div className="row" style={{ width: '100%' }}>
                                    {filteredCases.map((caseData) => (
                                        <div className="col mb-4 containerCasesImages" key={caseData.id}>
                                            <button className='btnImgCases' type="button" data-bs-toggle="modal" data-bs-target={`#modal${caseData.id}`}>
                                                <div className="img-wrapper">
                                                    <img
                                                        src={caseData.img}
                                                        alt={caseData.obra}
                                                        className="img-fluid"
                                                    />
                                                    <div className="overlay">
                                                        <div className="overlay-content">
                                                            <h3>{caseData.type}</h3>
                                                            <p>{caseData.obra}</p>
                                                            <p>{caseData.localidad}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </button>

                                            <div className="modal fade" id={`modal${caseData.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-90w">
                                                    <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h1 className="modal-title fs-5" id="exampleModalLabel">{caseData.type}</h1>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="row">
                                                            <div className="col-12 col-lg-6">
                                                                <div className="containerImgModal">
                                                                <img
                                                                    src={caseData.img}
                                                                    alt={caseData.obra}
                                                                    />
                                                                    </div>
                                                            </div>
                                                            <div className="col-12 col-lg-6">
                                                                <div className="d-column justify-content-around">
                                                                    <p><strong>Obra: </strong>{caseData.obra}</p>
                                                                    <p><strong>Locacion: </strong>{caseData.localidad}</p>
                                                                    <p><strong>M2: </strong>{caseData.mt2}</p>
                                                                    <p><strong>Empresa: </strong>{caseData.empresa}</p>
                                                                </div>
                                                                <p>{caseData.descripcion}</p>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="containerGallery">
                                                                        {caseData.gallery && caseData.gallery.map((img, index) => (
                                                                            <img
                                                                                key={index}
                                                                                src={img.url}
                                                                                alt={caseData.obra}
                                                                                style={{width: '100%'}}
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <APIProvider apiKey={process.env.REACT_APP_MAPS_KEY}>
                                <Map style={{ height: '74vh', width: '95%', margin: '0 auto' }} defaultZoom={defaultZoom} defaultCenter={{ lat: -36, lng: -62 }} gestureHandling={'greedy'} mapId={process.env.REACT_APP_MAPS_ID}>
                                    {filteredCases.map((caseData) => (
                                        <AdvancedMarker key={caseData.id} position={caseData.position}>
                                            <Pin background={"var(--colorTerciario)"} borderColor={"#282530"} glyphColor={"white"} glyph={"B"}/>
                                        </AdvancedMarker>
                                    ))}
                                </Map>
                            </APIProvider>
                            <div style={{ width: '95%', margin: '0 auto' }}>

                            <ImageWithTextButton
                                text={"¿QUIERES QUE TU EMPRESA SE CONVIERTA EN UN CASO DE ÉXITO?"}
                                buttonText={"Escribenos"}
                                src={'../../../assets/img/benefits/resistente/bannerResistentex400.jpg'}
                                height={400}
                                width={400}
                                />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </StyleSuccessCasesView>
        </>
    );
}

const StyleSuccessCasesView = styled.div`
    .title {
        margin-top: 7rem;
        @media (min-width: 768px) {
            margin-top: 10rem;
        }
    }
    .containerCheckbox {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        margin-top: 3rem;
        @media (min-width: 768px) {
            display: flex;
            justify-content: space-evenly;
        }
    }
    .containerCases {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-height: 400px;
        overflow-y: auto;
        scroll-snap-type: y mandatory;
        @media (min-width: 768px) {
            max-height: 1030px;
        }
    }
    .containerCasesImages {
        flex: 1 1 calc(50%);
        max-width: calc(50%);
        scroll-snap-align: center;
    }
    img {
        width: 100%;
        cursor: pointer;
    }
    .btnImgCases{
        border: none;
        background-color: transparent;
        cursor: pointer;
    }
    .modal-90w {
        max-width: 95% !important;
    }
    .containerImgModal {
        width: auto;
        height: 500px;
        overflow: hidden;
        position: relative;
    }
    .containerImgModal img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    .img-wrapper {
        position: relative;
        overflow: hidden;
        width: auto;
        max-height: 260px;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(15, 110, 0, 0.3); /* Verde con transparencia */
        opacity: 0;
        transition: opacity 0.3s ease;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
    }

    .img-wrapper:hover .overlay {
        opacity: 1;
    }

    .overlay-content {
        text-align: center;
    }
    .overlay-content h3 {
        color: white;
    }
    .overlay-content p {
        color: white;
    }
    .containerGallery {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        overflow: hidden;
        @media (min-width: 768px) {
        margin-top: 1rem;
            display: flex;
            justify-content: space-between;
            width: 24%;
            text-align: center;
            overflow: visible;
            height: 100%;
        }
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
`

const CheckBoxWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    justify-content: space-between;

    span {
        font-size: 0.8rem;
        color: #000;
        font-weight: bold;
        @media (min-width: 768px) {
            font-size: 1.2rem;
            }
    }

    input[type="checkbox"] {
        margin: 0rem 0.5rem;
        width: 20px;
        height: 20px;
        appearance: none; 
        border: 2px solid #ccc;
        border-radius: 3px;
        background-color: #fff;
        position: relative;
        cursor: pointer;
    }

    /* Efecto cuando el checkbox está marcado */
    input[type="checkbox"]:checked {
        background-color: var(--colorTerciario); /* Fondo verde cuando está marcado */
        border-color: var(--colorTerciario); /* Cambia el borde a verde también */
    }

    /* Añadir el icono con ::after */
    input[type="checkbox"]:checked::after {
        content: "\f00c"; /* Código Unicode del ícono de check de Font Awesome */
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: #fff; /* Color del icono */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px; /* Ajusta el tamaño del icono */
    }

`;

