import React, { useState } from 'react';
import Tag from './Tag';
import styled from 'styled-components';

export default function TagGrups(props) {
    const [visibleTags, setVisibleTags] = useState(5);

    const handleViewMore = () => {
        setVisibleTags(visibleTags + 5);
    };

    return (
        <StyleTagGrups>
            <div id="aplicaciones"></div>
            <div className="containerEspecial">
                <h2 className="containerTitle">TODAS LAS SOLUCIONES DE BARTON 1000</h2>
                <div className="row" style={{ justifyContent: 'center' }}>

                    {props.listTag.slice(0, visibleTags).map((data) => (
                        <Tag key={data.id} tagInfo={data} />
                    ))}
                </div>

                {visibleTags < props.listTag.length && (
                    <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                        <button onClick={handleViewMore} className="btn btn-primary">
                            Ver más
                        </button>
                    </div>
                )}
            </div>
        </StyleTagGrups>
    );
}

const StyleTagGrups = styled.div`
    .containerEspecial {
        padding: 0rem 1rem;
        margin-bottom: 5rem;
        @media (min-width: 768px) {
            padding: 0rem 1.8rem;
        }
    }
    h2 {
        text-align: center;
    }
    .containerTitle {
        margin-top: 5.5rem;
        @media (min-width: 768px) {
            margin-top: 7rem;
            margin-bottom: 2rem;
        }
    }
`;
