import { useState, useEffect } from "react";
import CustomerService from "../services/CustomerService";
export function useDolarPrice () {
    const [dolar, setDolar] = useState([]);  // ESTO ES PARA USAR CON API DE DOLAR.. CONTROLAR
    const [currentDate, setCurrentDate] = useState('');

    function capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    useEffect(() => {
        const getDolar = async () => {
            const dolarData = await CustomerService();
            setDolar(dolarData);
        };
        getDolar();

        const date = new Date();
        const year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'long' }); // Obtiene el nombre del mes
        const capitalizedMonth = capitalizeFirstLetter(month);
        setCurrentDate(`**Vigencia ${capitalizedMonth} ${year}`);
    }, []);

    return [dolar, currentDate]
}